import { useState, useEffect } from "react";

import Cover from "../../Components/UI/Gallery/Article-cover";
import monaBeesa from "./../../Components/Media/Contest/mona-beesa.jpg";
import logo from "./../../Components/Media/logos/mona-beesa-logos/mona-beesa-contest-all-outlined-logo.png";
import Beenta from "./../../Components/Media/Mascots/Beenta/Beenta-painting.png";

import backImage from "./../../Components/Media/gallery.jpg";
import BackButton from "./../../Components/UI/Buttons/Back-button";

import "./Contest.css";


function Contest() {
  const [showLogo, setShowLogo] = useState(false);

  
  useEffect(() => {
    window.scrollTo(0,0);

    // const storedTimestamp = localStorage.getItem("pageLoadTimestamp");
    // console.log("storedTimeStamp:" +storedTimestamp);

    const refreshedTime = new Date().getTime();
    const storedTime = localStorage.getItem("pageLoadTimeAnimationContest");

    const elapsedTime = refreshedTime - storedTime;
    
    
    if (storedTime === null ) {

      setShowLogo(false);
      const currentTime = new Date().getTime();
      localStorage.setItem('pageLoadTimeAnimationContest', currentTime);

    } else if (elapsedTime > 1800000 || elapsedTime < 5000) {

      setShowLogo(false);
      const currentTime = new Date().getTime();
      localStorage.setItem('pageLoadTimeAnimationContest', currentTime);

    } else {
      setShowLogo(false);
    }
    

    const timeout = setTimeout(() => {
      setShowLogo(false);
    }, 4300);

    return () => {
      clearTimeout(timeout);
    };

  }, []);



  if (showLogo === true) {
    return (
      <div className="background-image-contest">


        <div style={{ display: "inline-block", margin: "50px auto"}}>
            <img src={logo} alt="Logo" className="logonimation logo-animation" />
            <img src={Beenta} alt="Beenta" className="logonimation mascott-animation-contest" /> 
        </div>

    </div>
    );

  } else {
    return (
      <div className="background-image-contest-all">
        

        <div style={{}}>
          <div>
            <img src={logo} alt="Logo" className="logo-title-contest" />
            
            <p className="welcome-message mascott-message">
              
              "Celebrate Your Talent! Join Our Contest Today!
                  <br />
              Hey there! Beenta again, and I'm thrilled to invite you to participate in our exciting contest!
                  <br />
              Do you have a masterpiece waiting to be seen? Our contest is your chance to shine! Share your artwork and compete to become our next hall of famer.
                  <br />
              Showcase your talent, earn the prize, inspire others, gain social media followers, and earn recognition as one of our esteemed winners. Don't miss out on this incredible opportunity!
                  <br />
              Join the contest now and let your creativity soar!"
            </p>
            
          </div>
          <img src={Beenta} alt="Beenta" className="mascott-title-activity" />
        </div>

        <div className="cover-container">

          <Cover
            link= "rules"
            image={monaBeesa}
            title= "Rules"     
          />

          <Cover
            link= "/contest/rules"
            state= {{ from: "submissions" }}
            image={monaBeesa}
            title= "Submissions"      
          />

          <Cover
            link= "winners"
            image={monaBeesa}
            title= "Winners"      
          />

        </div>


        <BackButton 
          image={backImage} 
          width={200} 
          height={200} 
          unit="px"
          backToPage="Back to gallery"
          backTo="/gallery"
          fontFamily="pacifico"
          fontSize="1.5rem"
        />
        
      </div>
    );
  }
};

export default Contest;
