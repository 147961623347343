import { useState, useEffect } from "react";

import { NavLink } from "react-router-dom";

import "./Gallery-cover.css";


function Article(props) {
    const [activeImage, setActiveImage] = useState(false);
    const [activeTitle, setActiveTitle] = useState("");


    function imageHandler(title) {

        // if (activeImage && (title !== activeTitle)) {
        //     return;
        // } else if (activeImage && (title === activeTitle)) {
        //     return;
        // } else if (!activeImage) {
        //     setActiveImage(true);
        //     setActiveTitle(title);
        // }

        setActiveImage(!activeImage);
        setActiveTitle(title);

        console.log("title: " + title + ", activeImage: " + activeImage + ", activeTitle: " + activeTitle);
        
    };


    function handleClickOutside(event) {
        
        if (activeImage && !event.target.closest(".cover-image-gallery-container")) {
            setActiveImage(false);
        }
        // else if (activeImage && event.target.closest(".cover-image-gallery-container")) {
        //     setActiveImage(false);
        //     setActiveTitle(false);
        // }

        console.log("activeImage: " + activeImage + ", activeTitle: " + activeTitle + ", event.target.alt: " + event.target.alt);
    };


    useEffect(() => {

        document.addEventListener("click", handleClickOutside);

        return () => {
            document.removeEventListener("click", handleClickOutside);
        };

        // console.log("activeImage" + activeImage + ", activeTitle: " + activeTitle);
    }, [activeImage]);

    const title = props.title;

    return (
        <NavLink to={props.link} >
            {/* <div>&#10060;</div> */}
            <div 
                className={activeImage ? "cover-image-gallery-container activeImage" : "cover-image-gallery-container"} 
                onClick={({})=> {imageHandler(props.title)}}
            >
                <h3 className="cover-title-gallery">{props.title}</h3>
                <img className="cover-image-gallery" src={props.image} alt={props.title}/>
            </div>

        </NavLink>

    );
};

export default Article;