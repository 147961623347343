import { useState, useEffect } from "react";

import WeakyCard from "../../Components/UI/Weakybeedia/Weaky-card";

import honeyPot from "./../../Components/Media/Weakybeedia/image-opacity-down/honey-pot-50.jpg";
import honeybee from "./../../Components/Media/Weakybeedia/image-opacity-down/honeybee-dark-room-50.jpg";
import dykImage from "./../../Components/Media/Dyk/panda-ant.png";
import logo from "./../../Components/Media/logos/weakybeedia-logo-silver-green.png";
import bee from "./../../Components/Media/Icons/bee-icon.png";
import honey from "./../../Components/Media/Icons/honeycomb-icon.png";
import lightBulb from "./../../Components/Media/Icons/light-bulb-icon.png";
import Beeatrice from "./../../Components/Media/Mascots/Beeatrice/Beeatrice-greeting-with-book.png";

import "./All-Articles.css";


function AllArticles() {
  const [showLogo, setShowLogo] = useState(false);
  const [savedTime, setSavedTime] = useState(0);

  
  useEffect(() => {
    window.scrollTo(0,0);

    // const storedTimestamp = localStorage.getItem("pageLoadTimestamp");
    // console.log("storedTimeStamp:" +storedTimestamp);

    const refreshedTime = new Date().getTime();
    const storedTime = localStorage.getItem("pageLoadTimeAnimation");

    const elapsedTime = refreshedTime - storedTime;
    
    
    if (storedTime === null ) {

      setShowLogo(true);
      const currentTime = new Date().getTime();
      localStorage.setItem('pageLoadTimeAnimation', currentTime);

    } else if (elapsedTime > 1800000 || elapsedTime < 5000) {

      setShowLogo(true);
      const currentTime = new Date().getTime();
      localStorage.setItem('pageLoadTimeAnimation', currentTime);

    } else {
      setShowLogo(false);
    }

    // console.log("storedTime: " + storedTime + ", refreshedTime: " + refreshedTime + ", elapsedTime: " + elapsedTime);
    
    // if (storedTimestamp === null) {
      
    //   const currentTime = new Date().getTime();
    //   localStorage.setItem('pageLoadTimestamp', currentTime);
    // }  else {
      
    //   const storedTime = parseInt(storedTimestamp, 10);
    //   const currentTime = new Date().getTime();
    //   const elapsedTime = currentTime - storedTimestamp;
    //   console.log("currentTime: " + currentTime + ", storedTime: " + storedTime + ", elapsedTime: " + elapsedTime);

      
    //   if (elapsedTime > 60000 || elapsedTime < 10000) { // 3600000 milliseconds = 1 hour
    //     setShowLogo(true);
    //   } else {
    //     setShowLogo(false);
    //   }

    // }

    const timeout = setTimeout(() => {
      setShowLogo(false);
    }, 0);

    return () => {
      clearTimeout(timeout);
    };

  }, []);



  if (showLogo === true) {

    return (

        <div className="background-image-articles-all">

          <div className="logo-animation-div" style={{margin: "50px auto"}}>
              <img rel="preload" src={logo} alt="Logo" className="logonimation logo-animation" />
              <img rel="preload" src={Beeatrice} alt="Beeatrice" className="logonimation mascott-animation" /> 
          </div>

          {/* <div style={{opacity: "0", marginTop: "500px"}}>
              <p>Welcome!</p>
          </div> */}

      </div>
    );

  } else {

    return (

      <div className="background-image-articles-all">

        <div style={{position: "relative", paddingTop: "35px", width: "100%"}}>
          <div>
            <img src={logo} alt="Logo" className="logo-title-xl" />
            
            <p className="mascott-message">
              "Hey, hey, it's Beeatrice!
              <br/>
              Introducing Weakybeedia!
              <br/>
              A quick and simple way to learn the essentials about bees and their world!
              <br/>
              Then test your new knowledge with a one-question quiz at the end of each article!"
            </p>
            
          </div>
          <img src={Beeatrice} alt="Beeatrice" className="mascott-title" />
        

          <div className="weaky-card-container">

              <WeakyCard
                link= "honey"
                image={honeyPot}
                title= "Honey"
                icon={honey}
                description="Explore the history, production, health benefits, beliefs, and diverse flavors of honey in these comprehensive articles." 
                brightness=""
              />

              <WeakyCard
                link= "all-bee-articles"
                image={honeybee}
                title= "Bees"
                icon={bee}
                description="Discover the fascinating world of bees, including their behavior, importance to ecosystems, role in pollination, and the intricate workings of bee colonies in these insightful articles."
                brightness=""    
              />

              <WeakyCard
                link= "did-you-know"
                image={dykImage}
                title= "Fun Facts"
                icon={lightBulb}
                description="Slide through fun and interesting facts!"
                brightness="low-brightness"    
              />
            </div>

          </div>
        </div>
       
    );
  }
};

export default AllArticles;
