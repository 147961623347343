import { useState, useEffect } from "react";

import Articles from "../../Components/Articles/Articles";
import logo from "./../../Components/Media/logos/weakybeedia-logo-silver-green.png";
import Beeatrice from "./../../Components/Media/Mascots/Beeatrice/Beeatrice-greeting-with-book.png";
import honeyPot from "./../../Components/Media/Weakybeedia/honey-pot.jpg";
import posts from "./../../Components/Data-permanent/Weakybeedia/honey/honey.json";

import backImage from "./../../Components/Media/library.jpg";
import BackButton from "./../../Components/UI/Buttons/Back-button";

import "./All-Articles.css";


function AllArticles() {
    const [postsLocalDB, setPostsLocalDB] = useState([]);

    const fetchData = async () => {
        try {
        const response = await fetch('http://localhost:5000/honey');
        const data = await response.json();

        setPostsLocalDB(data);
        } catch (error) {
        console.error('Error fetching articles:', error);
        }
    };

    useEffect(() => {
      window.scrollTo(0,0);
    
        fetchData();

    }, []);


    return (

      <div style={{position: "relative"}}>

        <div className="background-image-articles-repeated"></div>

        <div style={{position: "relative", paddingTop: "15vh"}}>

          <img src={logo} alt="Logo" className="logo-title-xl" />

          <div>
            <p className="welcome-message mascott-message">
                  "Honey Articles"
            </p>
          </div>
          <img src={Beeatrice} alt="Beeatrice" className="mascott-title" />
          
          <div style={{margin: "20px auto"}}>
            <Articles 
              posts={posts}
              postsLocalDB={postsLocalDB}
              image={honeyPot}
            />
          </div>

          <BackButton 
                image={backImage} 
                width={200} 
                height={200} 
                unit="px"
                backToPage="Back to main menu"
                backTo="/all-articles"
                fontFamily="eb garamond"
                fontSize="1.3rem"
            />
          
        </div>
      </div>
    );

};

export default AllArticles;