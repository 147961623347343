import { useEffect } from "react";

import DidYouKnowUI from "../../Components/UI/Weakybeedia/Did-You-Know/DidYouKnow-UI";
import dykImage from "./../../Components/Media/Dyk/dyk-weaky-welcome-image-transparent.png";

import backImage from "./../../Components/Media/library.jpg";
import BackButton from "./../../Components/UI/Buttons/Back-button"

import "./All-Articles.css";

const DYK = () => {

    useEffect(() => {
        window.scrollTo(0,0);
    }, []);


    return (
        
        <div style={{position: "relative", paddingBottom: "30px"}}>
            <div className="background-image-dyk" ></div>

            <div style={{position: "relative", paddingTop: "50px"}}>
            
                <img rel="preload" className="dyk-main-image" src={dykImage} />

                <div className="weaky-card-container-dyk">
                    <DidYouKnowUI />
                </div>
            </div>

            <BackButton 
                image={backImage} 
                width={200} 
                height={200} 
                unit="px"
                backToPage="Back to main menu"
                backTo="/all-articles"
                fontFamily="eb garamond"
            />
        </div>
        
    );
};

export default DYK;