import { useEffect } from "react";


function PrivacyPolicy() {


    useEffect(() => {
        window.scrollTo(0,0);

    }, []);

    return (
        <div className="legals">
            <h2>Privacy Policy</h2>

            <p>
            At beexplorer.org, we take your privacy seriously. This Privacy Policy outlines how we collect, use, and protect your personal information when you use our website.
            </p>

            <p>
            <strong>Information Sharing</strong><br />
            We do not sell, trade, or otherwise transfer your personal information to third parties without your consent, except as required by law or to fulfill a legal obligation. We do not pass any information to Google that Google could use or recognize as personally identifiable information or that permanently identifies a particular device (such as a mobile phone's unique device identifier if such an identifier cannot be reset).
            </p>

            <p>
                <strong>Information We Collect</strong><br />
                We use Google Analytics to collect and process data about your use of our website. Google Analytics uses cookies and other tracking technologies to gather non-personally identifiable information about your interactions with our site. This information is used to improve the user experience and website functionality. We do not pass any information to Google that Google could use or recognize as personally identifiable information or that permanently identifies a particular device.
                <br /><br />
                When voluntarily submitting through our contact form or newsletter subscription, we naturally collect personal information such as your name and email address, and safely guard them until you unsubscribe.
            </p>

            <p>
            <strong>Google AdSense</strong><br />
            We use Google AdSense to serve ads when you visit our website. Google may use cookies and web beacons to collect information about your visits to this and other websites in order to provide advertisements about goods and services of interest to you. By using our website, you consent to the processing of data about you by Google in the manner and for the purposes set out above. For more information on how Google uses data when you use our partners' sites or apps, please visit How Google uses data when you use our partners’ sites or apps.
            </p>

            <p>
                <strong>Privacy Disclosures</strong><br />                Our privacy policy discloses any data collection, sharing, and usage that takes place on our site as a consequence of using Google products. It is important to note that third parties may place and read cookies on your browser or use web beacons to collect information as a result of ad serving on our website.
            </p>

            <p>
                <strong>Cookies</strong><br />                We and Google use cookies to enhance your experience, gather general visitor information, and track visits to our website. You can choose to have your computer warn you each time a cookie is being sent, or you can choose to turn off all cookies. You can do this through your browser settings or the consent form provided before navigating to our website. If you turn off cookies, some features that make your site experience more efficient may not function properly.
            </p>

            <p>
                <strong>Identifying Users</strong><br />                We do not use device fingerprints or locally shared objects (e.g., Flash cookies, Browser Helper Objects, HTML5 local storage) other than HTTP cookies or user-resettable mobile device identifiers designed for use in advertising. We do not pass any information to Google that Google could use or recognize as personally identifiable information or that permanently identifies a particular device.
                <br/><br />
                We do not pass any information to Google that Google could use or recognize as personally identifiable information or that permanently identifies a particular device (such as a mobile phone's unique device identifier if such an identifier cannot be reset).
                <br/><br />
                We do not use our services to identify users or facilitate the merging of personally identifiable information with information previously collected as non-personally identifiable information without providing robust notice and obtaining the user's prior affirmative (i.e., opt-in) consent to such identification or merger. Irrespective of users' consent, do not attempt to disaggregate data that Google reports in aggregate.
                <br />
                For more information, please refer to <a href="https://support.google.com/adsense/answer/6156630?visit_id=638562164818651020-620222495&ref_topic=6162392&rd=1" target="_blank">Guidance for complying with the Identifying Users Policy.</a>
                <br /><br />
                We comply with the <a href="https://www.google.com/about/company/user-consent-policy/" target="_blank">EU user consent policy.</a>
                <br /><br />
                We only use localStorage to enhance your browsing experience. Specifically, we use localStorage to store the page load time for animation purposes. This data is not used for advertising purposes.
            </p>

            <p>
                <strong>Use of Device and Location Data</strong><br />
                If we collect, process, or disclose information that identifies or can be used to infer an end user's precise geographic location, we will:
                <br/><br/>
                Disclose to the user the purposes for which their data may be used, including ad personalization, analytics, and attribution.
                <br/>
                Obtain express (i.e., opt-in) consent from end users before collecting, processing, or disclosing such information.
                <br/>
                Send such information to Google in an encrypted state or via an encrypted channel.
                <br/>
                Disclose such information collection, processing, or disclosure in all applicable privacy policies.
                <br/>
                <a href="https://support.google.com/publisherpolicies/answer/10437073?sjid=7304478184696862209-NA" target="_blank">Learn more about use of device and location data.</a>
            </p>

            <p>
                <strong>Children’s Online Privacy Protection Act (COPPA)</strong><br />                If we implement any Google advertising service on an app, a site, or a section of a site that is covered by COPPA, we will:
                <br/><br/>
                Notify Google of those sites or sections of sites covered by COPPA using the <a href="https://search.google.com/search-console/coppa" target="_blank">Google Search Console</a>, tag the ad request using the <a href="https://developers.google.com/admob/android/targeting" target="_blank">AdMob SDK</a>, or tag our site, app, or ad request for child-directed treatment.
                <br/>
                Not use interest-based advertising (including remarketing) to target past or current activity by users known to be under the age of 13 years or on sites directed at users under the age of 13 years.
                <a href="https://support.google.com/publisherpolicies/answer/10436800?sjid=1994732585042393206-NA" target="_blank"></a>
            </p>

            <p>
                <strong>Security</strong><br />                We implement industry-standard security measures to protect your personal information from unauthorized access, disclosure, alteration, or destruction. However, please be aware that no method of transmission over the internet or electronic storage is 100% secure.
            </p>

            <br />

            <p>
                <strong>Contact Us</strong><br />                Thank you for entrusting us with your personal information. If you have any questions or concerns about our Privacy Policy, please contact us via our email <a href="mailto:beexplorer.beexplorer@outlook.com">beexplorer.beexplorer@outlook.com</a>.
            </p>

        </div>
    );
};

export default PrivacyPolicy;