import { useEffect } from "react";

function Contact() {

    useEffect(() => {
        window.scrollTo(0,0);

    }, []);

    return (
        <div className="legals">
            <h2>Contact Information</h2>
            <p>Feel free to reach out to us via email:</p>
            <p><a href="mailto:beexplorer.beexplorer@outlook.com">beexplorer.beexplorer@outlook.com</a></p>
        </div>
    );
};

export default Contact;