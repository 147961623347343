import { useEffect } from "react";

import backImage from "./../../Components/Media/contest.jpg";
import BackButton from "./../../Components/UI/Buttons/Back-button";

import "./Winners.css";

import winners from "./../../Components/Media/winners.jpg";
import Beenta from "./../../Components/Media/Mascots/Beenta/Beenta-painting.png";
import logo from "./../../Components/Media/logos/mona-beesa-logos/mona-beesa-winners-logo.png";


function AllArticles() {

    useEffect(() => {
        window.scrollTo(0,0);
  
      }, []);


    return (
        <div style={{position: "relative"}}>

            <div className="background-image-winners"></div>

            <div style={{position: "relative"}}>
                <div className="winners-container" style={{textAlign: "center"}}>
                    <div>
                        <img src={logo} alt="Logo" className="logo-title-contest" />
                        <p className="welcome-message mascott-message">
                            "Who will be our Winners??
                            <br/>
                            We wonder!
                            <br/>
                            Participate and be one of them!!"
                        </p>

                        <img src={Beenta} alt="Beenta" className="mascott-intro-winners" />
                        <div>
                            <img src={winners} alt="Frame" className="logo-frame" />
                            <img src={winners} alt="Frame" className="logo-frame" />
                            <img src={winners} alt="Frame" className="logo-frame" />
                        </div>
                                 
                    </div>
                </div>

                <BackButton 
                    image={backImage} 
                    width={200} 
                    height={200} 
                    unit="px"
                    backToPage="Back to contest"
                    backTo="/contest"
                    fontFamily="pacifico"
                    fontSize="1.5rem"
                />

            </div>
        </div>
    );

};

export default AllArticles;
