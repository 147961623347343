import { useState, useEffect } from "react";

import Articles from "../../Components/Articles/Articles";
import logo from "./../../Components/Media/logos/weakybeedia-logo-silver-green.png";
import Beeatrice from "./../../Components/Media/Mascots/Beeatrice/Beeatrice-greeting-with-book.png";
import posts from "./../../Components/Data-permanent/Weakybeedia/bees/honeybees.json";
import image from "./../../Components/Media/Weakybeedia/bumblebee-dark-room.jpg";

import backImage from "./../../Components/Media/honeybee-pink-flower-sunrise.jpg";
import BackButton from "./../../Components/UI/Buttons/Back-button"

import "./All-Articles.css";


function AllArticles() {
  const [postsDB, setPostsDB] = useState([]);

  const fetchData = async () => {
      try {
      const response = await fetch('http://localhost:5000/honeybees');
      const data = await response.json();
      setPostsDB(data);
      } catch (error) {
      console.error('Error fetching articles:', error);
      }
  };

  useEffect(() => {
    window.scrollTo(0,0);
  
      fetchData();

  }, []);


  return (
    <div style={{position: "relative"}}>

      <div className="background-image-articles-repeated"></div>

        <div style={{position: "relative", paddingTop: "15vh"}}>
          
          <img src={logo} alt="Logo" className="logo-title-xl" />

          <div>
            <p className="welcome-message mascott-message">
                  "Honeybee Special Edition"
            </p>
          </div>
          <img src={Beeatrice} alt="Beeatrice" className="mascott-title" />
          
          <div style={{margin: "20px auto"}}>
            <Articles 
              posts={posts}
              image={image}
            />
          </div>
          
          <BackButton 
                image={backImage} 
                width={200} 
                height={200} 
                unit="px"
                backToPage="Back to bee menu"
                backTo="/all-bee-articles"
                fontFamily="eb garamond"
                fontSize="1.3rem"
          />

        </div>
      </div>
  );

};

export default AllArticles;