
import comingSoon from "./../../Media/Recipes/coming-soon.png";

import "./Advices-card.css";

import adviceCard from "./../../Media/Recipes/advices-card.png";


function AdvicesCard() {
     
    return (
        <div class="advices-card">
            <img src={adviceCard} className="advices-card-image"/>
            <img src={comingSoon} className="advices-card-coming-soon"/>
        </div>
    );
};

export default AdvicesCard;

