import { useState, useContext, useCallback } from 'react';
import { GoogleReCaptcha } from "react-google-recaptcha-v3";
import { FunctionsContext } from "./../store/Functions-context";

// import axios from "axios";

import "./Subscribe.css";
import { httpsCallable } from 'firebase/functions';

import hourglass from "./../Media/Gifs/hourglass.gif";

function SubscriptionForm(props) {
  const [token, setToken] = useState("");
  const [refreshReCaptcha, setRefreshReCaptcha] = useState(false);

  const [email, setEmail] = useState('');
  const [displaySuccessSubMessage, setDisplaySuccessSubMessage] = useState("sub-message-initial");
  const [displayErrorSubMessage, setDisplayErrorSubMessage] = useState("sub-message-initial");
  const [loading, setLoading] = useState(false);

  const FunctionsCtxt = useContext(FunctionsContext);
  

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      // const response = await axios.post(process.env.REACT_APP_FUNCTIONS_URL, { email, token });

      // await Subscription(email, token);

      const subscribe = httpsCallable(FunctionsCtxt.functions, "subscribe");

      subscribe({email: email, token}).then(result => {
        const data = result.data;
        const message = data.message;

        if (message === "success") {
          setLoading(false);
          setDisplaySuccessSubMessage("success-message");
          setDisplayErrorSubMessage("sub-message-initial");
        } else {
          setLoading(false);
          setRefreshReCaptcha(refreshReCaptcha => !refreshReCaptcha);
          setDisplayErrorSubMessage("error-message");
          setDisplaySuccessSubMessage("sub-message-initial");
        }

      });
      

    } catch {
      setLoading(false);
      setRefreshReCaptcha(refreshReCaptcha => !refreshReCaptcha);
      setDisplayErrorSubMessage("error-message");
      setDisplaySuccessSubMessage("sub-message-initial");
    }

  };


  const setTokenFunc = useCallback((getToken) => {
    setToken(getToken);
  });



  return (
    <form 
      onSubmit={handleSubmit}
      method="post"
    >
      <input
        type="email"
        id="email"
        name="email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        placeholder="Email:"
        
      />

      <GoogleReCaptcha
          className="google-recaptcha-custom-class"
          onVerify={setTokenFunc}
          refreshReCaptcha={refreshReCaptcha}    
      />
      <button className="button-subscribe" type="submit">Subscribe</button>
      <img src={hourglass} className={loading ? "hourglass" : "hourglass hidden"} />


      <p className={displaySuccessSubMessage} >Subscription Successful!</p>
      <p className={displayErrorSubMessage} >Error: Something went wrong or you're already subscribed.</p>
    </form>
  );
}

export default SubscriptionForm;
