import { useState, useEffect } from "react";
import Markdown from "react-markdown";
import rehypeRaw from "rehype-raw";

import "./Quiz.css";

import Beeatrice from "./../../Media/Mascots/Beeatrice/Beeatrice-reading.png";
import BeeatriceGreeting from "./../../Media/Mascots/Beeatrice/Beeatrice-greeting-with-book.png";

const Quiz = ({post}) => {
    const [answer, setAnswer] = useState("");
    const [showAnswer, setShowAnswer] = useState(false);
    const [showHint, setShowHint] = useState(false);
    const [showExplanation, setShowExplanation] = useState(false);


    const answerHandler = () => {
        setShowAnswer(!showAnswer);
    };

    const hintHandler = () => {
        setShowHint(!showHint);
    };

    const explanationHandler = () => {
        setShowExplanation(!showExplanation);
    };


    useEffect(() => {

        switch (post.answer) {
            case "a":
                setAnswer(post.choices.a);
                return;

            case "b":
                setAnswer(post.choices.b);
                return;

            case "c":
                setAnswer(post.choices.c);
                return;

            case "d":
                setAnswer(post.choices.d);
                return;
            
            default:
                setAnswer(post.answer);
                return;
        }

    }, []);


    if (post.question.length < 1) {

        return (<></>);

    } else {

        return (
            <div className="quiz-container">

                <h1>Quiz Time</h1>

                <div>
                    <p className="post-punchline mascott-message" style={{backgroundColor: "rgba(40, 167, 69, .7) !mportant"}} >"{post.question}"</p>
                    <img src={Beeatrice} style={{width: "86px", display: "inline-block"}}/>
                </div>

                <div style={{position: "relative"}} >
                    <div className="quiz-choices-buttons-container">
                        <div className="quiz-choices-container" >
                            <p className="quiz-choices">
                                {post.choices.a.length > 1 ? <div> A. {post.choices.a}<br /></div> : <></>}
                                {post.choices.b.length > 1 ? <div> B. {post.choices.b}<br /></div> : <></>}
                                {post.choices.c.length > 1 ? <div> C. {post.choices.c}<br /></div> : <></>}
                                {post.choices.d.length > 1 ? <div> D. {post.choices.d}<br /></div> : <></>}
                            </p>
                        </div>

                        <div className="quiz-button-container">
                            <button className="quiz-button btn-hint btn btn-warning txt-dark" onClick={hintHandler}>Hint</button>
                            <button className="quiz-button btn-answer btn btn-success" onClick={answerHandler}>Answer</button>
                        </div>
                    </div>
                </div>

                <div className="quiz-answers-container">
                    {
                        showHint ? 
                            <p className="quiz-hint">{post.hint}</p>
                        : <></>
                    }
                    {
                        showAnswer ? 
                            <div>
                                <p className="quiz-answer">{answer}</p>
                                <button className="quiz-button btn-explanation btn btn-primary" onClick={explanationHandler}>Explanation</button>

                                {
                                    showExplanation ? 
                                        <p className="quiz-explanation">
                                            <Markdown className="explanation" rehypePlugins={[rehypeRaw]}>{post.explanation}</Markdown>
                                            <img src={BeeatriceGreeting} className="post-mascott-explanation"/>
                                        </p>
                                    : <></>
                                }
                            </div>
                        : <></>
                    }
                    
                </div>

            </div>
        );
    }
};

export default Quiz;