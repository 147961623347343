import { useEffect } from "react";
import "./Hive-of-fame.css";

import winners from "./../../Components/Media/winners.jpg";
import logo from "./../../Components/Media/logos/mona-beesa-logos/mona-beesa-hof-shorter-logo.png";
import Beenta from "./../../Components/Media/Mascots/Beenta/Beenta-painting.png";

import backImage from "./../../Components/Media/gallery.jpg";
import BackButton from "./../../Components/UI/Buttons/Back-button";

function HiveOfFame() {

    useEffect(() => {
        window.scrollTo(0,0);
    }, []);
    

    return (
        <div style={{position: "relative"}}>
            <div className="background-image-hive-of-fame"></div>
        
            <div style={{paddingTop: "33%", position: "relative"}}>
                <div className="winners-container" style={{textAlign: "center"}}>
                    <div>
                        <p className="welcome-message mascott-message">
                            "Beenta Again!!
                            <br/>
                            Welcome to the Hive Of Fame, where winners, well-deserving individuals, philanthropists, and achievers are displayed!
                            <br/>
                            The room has yet to be filled, so join the Contest and bee the first!"
                        </p>

                        <img src={Beenta} alt="Beenta" className="mascott-intro-winners" />
                        <div>
                            <img src={winners} alt="Frame" className="logo-frame" />
                            <img src={winners} alt="Frame" className="logo-frame" />
                            <img src={winners} alt="Frame" className="logo-frame" />
                        </div>

                        <img src={logo} alt="Logo" className="logo-title" />

                    </div>
                </div>   

                <BackButton 
                    image={backImage} 
                    width={200} 
                    height={200} 
                    unit="px"
                    backToPage="Back to gallery"
                    backTo="/gallery"
                    fontFamily="pacifico"
                    fontSize="1.5rem"
                />

            </div>
        </div>
    );
};

export default HiveOfFame;