import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Markdown from "react-markdown";
import rehypeRaw from "rehype-raw";

import backImage from "./../../../Components/Media/BBS-news/BBS-news-earth.jpg";
import BackButton from "./../../../Components/UI/Buttons/Back-button";

import "./PostNews.css";

import BeeBee from "./../../../Components/Media/Mascots/BeeBee/Beebee-reporting.png";
import postsNews from "./../../../Components/Data-permanent/BBS-news/news/news.json";
import postsInterviews from "./../../../Components/Data-permanent/BBS-news/interviews/interviews.json";


const Post = () => {
    const [post, setPost] = useState("");

    
    const params = useParams();

    // const fetchPost = async () => {
    //     try {
            
    //         const response = await fetch(`http://localhost:5000/${params.news}`);

    //         const data = await response.json();
    //         console.log(data);

    //         const wantedNews = data.find(news => news.title === params.post );

    //         console.log("params.news: " + params.news + ", params.post: " + params.post);
    //         console.log(wantedNews);
    //         setPosts(wantedNews);
    //     } catch (error) {
    //         console.error('Error fetching post:', error);
    //         }
    // };


    const fetchPost = async () => {

        try {
            let wantedArticle;

            if (params.article === "news") {
                wantedArticle = postsNews.find(article => article.title === params.post );

            } else if (params.article === "interviews") {
                wantedArticle = postsInterviews.find(article => article.title === params.post );

            }

            setPost(wantedArticle);
            
        } catch (error) {
            console.error('Error fetching post:', error);
        }
    };


    useEffect(() => {
        window.scrollTo(0,0);
    
        fetchPost();

    }, []);

    return (
        <div style={{position: "relative", padding: "30px"}}>
            <div className="background-image-post-news"></div>
                <div><p></p></div>

            <div className="post-container">
                <div className="post-title-content">
                    <div className="post-top">
                        <div>
                            <h1 className="post-news-title">{post.title}
                                <img src={BeeBee} className="post-mascott"/>
                            </h1>
                        </div>
                        
                        
                    </div>
                    
                    <Markdown className="post-content-news" rehypePlugins={[rehypeRaw]}>{post.content}</Markdown>
                </div>

                <div>
                    <img src={BeeBee} className="mascott-punchline"/>
                    <p className="post-punchline mascott-message">"{post.punchline}"</p>
                </div>

                <div>
                    <div class="post-author-date-news"><p>{post.author}</p></div>
                    
                    <div class="post-author-date-news"><p>{post.date}</p></div>
                </div>

            </div>

            <BackButton 
                image={backImage} 
                width={200} 
                height={200} 
                unit="px"
                backToPage="Back to news"
                backTo="/bbs-news/news"
                fontFamily="courier prime"
                fontSize="1.3rem"
            />
        </div>  
        
    );
};

export default Post;