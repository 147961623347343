import { useState, useEffect } from "react";
import "./Did-you-know-UI.css";

import weakyFacts from "./../../../Data-permanent/Weakybeedia/facts/dyk/dyk-weakybeedia.json";
import DYKSlides from "./Did-you-know-slide";

const DYK = () => {
    const [windowWidth, setWindowWidth] =useState(window.innerWidth);


    function handleResize() {
        setWindowWidth(window.innerWidth);
    };


    useEffect(() => {

        window.addEventListener('resize', handleResize);


        return () => {
            window.removeEventListener('resize', handleResize);
        };

    }, []);


    if (windowWidth >= 510) {

        return (
            <>
                {weakyFacts.map((fact, index) => {
                    return (
                        <DYKSlides 
                            fact={fact}
                            index={index}
                            divWidth={500}
                            unit="px"
                    />
                    );
                    
                })} 
            </>
        );
    } else {
        return (
            <>
                {weakyFacts.map((fact, index) => {
                    return (
                        <DYKSlides 
                            fact={fact}
                            index={index}
                            divWidth={90}
                            unit="vw"
                    />
                    );
                    
                })} 
            </>
        );
    }
};

export default DYK;