import { NavLink } from "react-router-dom";

import "./Weaky-card.css";

function WeakyCard(props) {

    const imageClass = "weaky-book-image " + props.brightness;

    return (

        // <div className="card-section">
        //         <div className="card">
        //             <div className="flip-card">
        //                 <div className="flip-card__container">
        //                     <div className="card-front">
        //                         <div className="card-front__tp card-front__tp--camping">
        //                             <h2 className="card-front__heading">
        //                                 {props.title}
        //                             </h2>
        //                         </div>

        //                         <div class="card-front__bt">
        //                                 <img src={props.icon} className="card-front_icon" />
        //                         </div>
        //                     </div>

        //                     <div className="card-back">
        //                         <img src={props.image} className="video__container"/>
        //                     </div>
        //                 </div>
        //             </div>

        //             <div className="inside-page">
        //                 <div className="inside-page__container">
        //                     <h3 className="inside-page__heading inside-page__heading--camping">
        //                         {props.title}
        //                     </h3>
        //                     <p className="inside-page__text">
        //                         {props.description}
        //                     </p>

        //                     <NavLink to={props.link} className="nav-link">
        //                     <div className="honeycomb">
        //                         {/* <p href="#" className="inside-page__btn inside-page__btn--camping">Explore</p> */}
        //                         <p style={{position: "relative", color: "rgb(236, 232, 232)", fontWeight: "700", fontSize: "1.5rem"}}>Explore</p>
        //                     </div>
        //                     </NavLink>

        //                 </div>
        //             </div>
        //         </div>
            
        // </div>
        

        
            <div className="book-weaky">
                <img src={props.image} className={imageClass} />
                
                <div className="weaky-book-inside">
                    <div className="weaky-book-inside-container">
                        <div className="weaky-cover-inside-heading">{props.title}</div>
                        <p className="weaky-cover-inside-description">{props.description}</p>
                    </div>
                    
                    <NavLink to={props.link} className="">
                        <div className="weaky-honeycomb">Explore</div>
                    </NavLink>
                    
                </div>

                
                <div className="cover-weaky">
                    
                    <div className="weaky-cover-top">
                        <div className="weaky-cover-heading">{props.title}</div>
                    </div>
                    
                    <img src={props.icon} className="weaky-cover-icon" />
                    
                </div>
            </div>
    );
};

export default WeakyCard;