import { NavLink } from "react-router-dom";

import "./Article-cover.css";


function Article(props) {
    
    return (
        <NavLink to={props.link} state={props.state} >

            <div className="cover-card">

                <div className="">
                    <div style={{height: "fit-content"}}>
                        <img className="cover-image" src={props.image} alt="Mona Beesa"/>
                    </div>
                    
                    <p className="cover-title">{props.title}</p>
                </div>
                
            </div>

        </NavLink>

    );
};

export default Article;