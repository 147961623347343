import Navbar from "./../Components/Partials/Navbar";


function Error() {

    return (
        <>
            <Navbar />
            <h1>Error!</h1>
            <h3>This page could not be found!</h3>
        </>
    );
};

export default Error;