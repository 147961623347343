import { useState, useEffect } from "react";

import "./Beehive.css";
import Cover from "../../Components/UI/Gallery-cover";
// import Parchment from "./../../Components/UI/Parchment-cover";

import honeybee from "./../../Components/Media/honeybee.webp";
import logo from "./../../Components/Media/beexplorer-logo.png";
import Beenta from "./../../Components/Media/Mascots/Beenta/Beenta-flying.png";

import beehive from "./../../Components/Media/beehive.jpg";
import library from "./../../Components/Media/library.jpg";
import studyRoom from "./../../Components/Media/libraryQueen.jpg";
import kitchen from "./../../Components/Media/kitchen.jpg";
import kitchenPost from "./../../Components/Media/kitchen-post.jpg";
import shop from "./../../Components/Media/BeeShop.jpg";
import gallery from "./../../Components/Media/gallery.jpg";
import auditorium from "./../../Components/Media/contest.jpg";
import hiveOfFame from "./../../Components/Media/hive-of-fame.jpg";
import garden from "./../../Components/Media/lavender-field.jpg";

function AllArticles() {
  const [showLogo, setShowLogo] = useState(false);
  const [savedTime, setSavedTime] = useState(0);
  const [rooms, setRooms] = useState([]);

  
  useEffect(() => {
    window.scrollTo(0,0);


    // const storedTimestamp = localStorage.getItem("pageLoadTimestamp");
    // console.log("storedTimeStamp:" +storedTimestamp);

    const refreshedTime = new Date().getTime();
    const storedTime = localStorage.getItem("pageLoadTimeAnimationBeehive");

    const elapsedTime = refreshedTime - storedTime;
    
    
    if (storedTime === null ) {

      setShowLogo(true);
      const currentTime = new Date().getTime();
      localStorage.setItem('pageLoadTimeAnimationBeehive', currentTime);

    } else if (elapsedTime > 1800000 || elapsedTime < 5000) {

      setShowLogo(true);
      const currentTime = new Date().getTime();
      localStorage.setItem('pageLoadTimeAnimationBeehive', currentTime);

    } else {
      setShowLogo(false);
    }

    // console.log("storedTime: " + storedTime + ", refreshedTime: " + refreshedTime + ", elapsedTime: " + elapsedTime);
    console.log(localStorage);


    // if (storedTimestamp === null) {
      
    //   const currentTime = new Date().getTime();
    //   localStorage.setItem('pageLoadTimestamp', currentTime);
    // }  else {
      
    //   const storedTime = parseInt(storedTimestamp, 10);
    //   const currentTime = new Date().getTime();
    //   const elapsedTime = currentTime - storedTimestamp;
    //   console.log("currentTime: " + currentTime + ", storedTime: " + storedTime + ", elapsedTime: " + elapsedTime);

      
    //   if (elapsedTime > 60000 || elapsedTime < 10000) { // 3600000 milliseconds = 1 hour
    //     setShowLogo(true);
    //   } else {
    //     setShowLogo(false);
    //   }

    // }

    const timeout = setTimeout(() => {
      setShowLogo(false);
    }, 4300);

    return () => {
      clearTimeout(timeout);
    };

  }, []);



  if (showLogo === true) {

    return (
      <div className="" style={{minHeight: "1000vh"}}>


        <div>
          <h1></h1>
        </div>

        <div style={{ display: "inline-block", margin: "50px auto"}}>
            <img src={beehive} alt="Logo" className="logonimation beehive-animation" />
        </div>

    </div>
    );

  } else {

    return (
      <div style={{position: "relative"}}>
        <div className="background-image-beehive"></div>
        

        <div style={{position: "relative", paddingTop: "40px"}}>
          <div style={{marginTop: "20px"}}>
            <div>

              <div className="beehive-title-container">
                <h1 className="beehive-title">Sweet Castle</h1>
              </div>
              
              <p className="beehive-para">The Beehive</p>
              
              <p className="welcome-message mascott-message">
                "Welcome to Sweet Castle! 
                <br/>
                You may approach
                <br/>
                and take a closer look at the inside!
              </p>
              
            </div>
            <img src={Beenta} alt="Beenta" className="mascott-title" />
          </div>

          <div className="cover-container">

            
            <Cover
                link= ""
                image={beehive}
                title= "The Beehive"    
            />

            <Cover
                link= ""
                image={library}
                title= "Library"    
            />

            <Cover
                link= ""
                image={studyRoom}
                title= "Reading Club"    
            />

            <Cover
                link= ""
                image={kitchen}
                title= "Kitchen"    
            />

            <Cover
                link= ""
                image={kitchenPost}
                title= "Resettled Kitchen"    
            />

            <Cover
                link= ""
                image={gallery}
                title= "Gallery"    
            />

            <Cover
                link= ""
                image={auditorium}
                title= "Auditorium"    
            />

            <Cover
                link= ""
                image={hiveOfFame}
                title= "Hive-Of-Fame"    
            />

            <Cover
                link= ""
                image={garden}
                title= "Garden"    
            />

            <Cover
                link= ""
                image={shop}
                title= "Shop"    
            />    
          </div>
        </div>
          
      </div>
    );
  }
};

export default AllArticles;
