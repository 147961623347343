import { useEffect, useState } from "react";

import NewsCardArticles from "./../../Components/UI/BBS-News/News-card-articles";
import logo from "./../../Components/Media/logos/bbs-logos/bbs-logo.png";
import BeeBee from "./../../Components/Media/Mascots/BeeBee/Beebee-reporting.png";
import posts from "./../../Components/Data-permanent/BBS-news/news/news.json";

import backImage from "./../../Components/Media/news.jpg";
import BackButton from "./../../Components/UI/Buttons/Back-button";

import "./News.css";


function News() {

    const [postsDB, setPostsDB] = useState([]);

    const fetchData = async () => {
        try{
          const response = await fetch("http://localhost:5000/news");
          const data = await response.json();
            setPostsDB(data);
        } catch (error) {
        console.error('Error fetching articles:', error);
        };
    };


      useEffect(() => {
        window.scrollTo(0,0);

        fetchData();

      }, []);


    return (
        <div style={{position: "relative"}}>

            <div className="background-image-news-repeated"></div>

            <div style={{position: "relative", paddingTop: "15vh"}}>

                <div>
                    <img src={logo} alt="Logo" className="logo-title" />
                    <p className="welcome-message mascott-message">
                    "BBS News"
                    </p>
            
                </div>
                <img src={BeeBee} alt="Beeatrice" className="mascott-title" />
            

                
                <div className="news-card-container">
                    <NewsCardArticles 
                        posts={posts}
                    />
                </div>


                <BackButton 
                    image={backImage} 
                    width={200} 
                    height={200} 
                    unit="px"
                    backToPage="Back to main menu"
                    backTo="/bbs-news"
                    fontFamily=""
                />

            </div>
        </div>
    );
};

export default News;