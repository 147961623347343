import { NavLink } from "react-router-dom";
import "./Back-button.css";

function BackButton(props) {

    return (
        <div className="bb-main-container"
            style={{
                width: `${props.width + props.unit}`, 
                height: `${props.height + props.unit}`,
                fontFamily: `${props.fontFamily}`,
                fontSize: `${props.fontSize}`
            }}
        >

            <div 
                className="bb-container"
                style={{backgroundImage: `url(${props.image})`}} 
            >
                <NavLink to={props.backTo} className="navLink">
                    <div className="bb-container-para">
                        <p style={{fontSize: `${props.fontSize}`}}>
                            ↢{props.backToPage}.
                        </p>
                    </div>
                </NavLink>
                

            </div>

        </div>
        
    );
};

export default BackButton;