import { useState, useEffect } from "react";

import Cover from "../../Components/UI/Gallery/Article-cover";
import monaBeesa from "./../../Components/Media/Gallery/mona-beesa.jpg";
import logo from "./../../Components/Media/logos/mona-beesa-logos/mona-beesa-gallery-all-outlined-logo.png";
import Beenta from "./../../Components/Media/Mascots/Beenta/beenta-paints-mona-beesa-black-bg.png";

import "./Gallery.css";


function Gallery() {
  const [showLogo, setShowLogo] = useState(false);
  const [savedTime, setSavedTime] = useState(0);

  
  useEffect(() => {
    window.scrollTo(0,0);

    // const storedTimestamp = localStorage.getItem("pageLoadTimestamp");
    // console.log("storedTimeStamp:" +storedTimestamp);

    const refreshedTime = new Date().getTime();
    const storedTime = localStorage.getItem("pageLoadTimeAnimationGallery");

    const elapsedTime = refreshedTime - storedTime;
    
    
    if (storedTime === null ) {

      setShowLogo(true);
      const currentTime = new Date().getTime();
      localStorage.setItem('pageLoadTimeAnimationGallery', currentTime);

    } else if (elapsedTime > 1800000 || elapsedTime < 5000) {

      setShowLogo(true);
      const currentTime = new Date().getTime();
      localStorage.setItem('pageLoadTimeAnimationGallery', currentTime);

    } else {
      setShowLogo(false);
    }

    // console.log("storedTime: " + storedTime + ", refreshedTime: " + refreshedTime + ", elapsedTime: " + elapsedTime);
    console.log(localStorage);

    const timeout = setTimeout(() => {
      setShowLogo(false);
    }, 0);

    return () => {
      clearTimeout(timeout);
    };

  }, []);



  if (showLogo === true) {
    return (
      <div className="background-image-gallery">

        <div style={{}}>
            <img rel="preload" src={logo} alt="Logo" className="logonimation logo-animation" />
            <img rel="preload" src={Beenta} alt="Beenta" className="logonimation mascott-animation2" /> 
        </div>

    </div>
    );

  } else {
    return (
      
      <div className="background-image-gallery">

          <div style={{}}>
            <div>
              <img src={logo} alt="Logo" className="logo-title" />
              
              <p className="mascott-message">
                "Hello, I'm Beenta, and welcome to my Gallery, "Mona Beesa"!
                    <br />
                Here, we showcase artworks that inspire and captivate. But our walls are not yet complete – they're waiting for your masterpiece to adorn them!
                    <br />
                Join our contest and become a hall of famer, showcasing your talent for all to admire and appreciate!"
              </p>
              
            </div>
            <img src={Beenta} alt="Babee" className="mascott-title-activity" />
          </div>

          <div className="cover-container">

            <Cover
              link= ""
              image={monaBeesa}
              title= "Beenta's Masterpieces"     
            />

            <Cover
              link= "/contest"
              image={monaBeesa}
              title= "Contest"      
            />

            <Cover
              link= "/gallery/hive-of-fame"
              image={monaBeesa}
              title= "Hive of Fame"     
            />

          </div>       
      </div>
    );
  }
};

export default Gallery;
