import { Link } from "react-router-dom";

import "./Article.css";


function Article(props) {
    
    return (
            <div className="article-card">
                <div className="article-card-content">
                    <img className="article-card-image" src={props.image} alt="honeybee"/>
                    <h3 className="article-card-title">{props.post.title}</h3>
                    <p className="article-card-date">{props.post.date}</p>
                </div>
            </div>
    );
};

export default Article;