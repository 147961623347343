import { useEffect, useState } from "react";

import "./Shop.css";


function Shop() {
  const [showLink, setShowLink] = useState(false);

  useEffect(() => {
    window.scrollTo(0,0);
    window.location.href = 'https://beexplorer.shop/';

    const linkToShop = setTimeout(() => {
      setShowLink(true);
    }, 3000);

    return () => {
      clearTimeout(linkToShop);
    };

  }, []);

  return (

    <div className="background-image-shop">

      <div style={{position: "relative", paddingTop: "100px", width: "100%"}}>
        <h3 className="shop-title">Redirecting to shop...</h3>
        {showLink 
        ? <a className="shop-title-2" href="https://beexplorer.shop" target="_blank">...or click here 🏪</a>
        : <></>}
      </div>

    </div>    
  );
};

export default Shop;