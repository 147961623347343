import { useState, useEffect } from "react";

import RecipesCardArticles from "../../Components/UI/Recipes/Recipes-card-articles";

import backImage from "./../../Components/Media/kitchen.jpg";
import BackButton from "./../../Components/UI/Buttons/Back-button";

import "./Recipes.css";

import logo from "./../../Components/Media/logos/babeelicious-logo.png";
import BaBee from "./../../Components/Media/Mascots/Babee/Babee-cooking.png";
import posts from "./../../Components/Data-permanent/Recipes/postRecipes/postrecipes.json";


function Recipes() {
  const [postsDB, setPostsDB] = useState([]);

  async function fetchData() {
    try {
      const response = await fetch("http://localhost:5000/recipes");
      
      const data = await response.json();
      console.log(data);

      setPostsDB(data);
    } catch (error) {
      console.error('Error fetching articles:', error);
    }
  };

  useEffect(() => {
    window.scrollTo(0,0);

    fetchData();

    localStorage.setItem("mascott", "Beeatrice");

  }, []);

  return (
    
    <div style={{position: "relative"}}>

      <div className="background-image-recipes-repeated"></div>

      <div style={{position: "relative", padding: "15vh 0 20px"}}>

        <img src={logo} alt="Logo" className="logo-title-xl" />

        <div>
          <p className="welcome-message mascott-message">
              "Try these Delicious Recipes !!"
            </p>
          <img src={BaBee} alt="Babee" className="mascott-title" />
        </div>

        <div style={{margin: "20px auto"}}>
            <RecipesCardArticles
              posts={posts}
            />
        </div>


        <BackButton 
          image={backImage} 
          width={200} 
          height={200} 
          unit="px"
          backToPage="Back to main menu"
          backTo="/main-recipes"
          fontFamily="poppins"
          fontSize="1.1rem"
        />

      </div>
    </div>
  );
};
  
  export default Recipes;