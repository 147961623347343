import { useEffect } from "react";
import { useLocation } from "react-router-dom";

import backImage from "./../../Components/Media/contest.jpg";
import BackButton from "./../../Components/UI/Buttons/Back-button";

import "./Rules.css";

import logo from "./../../Components/Media/logos/mona-beesa-logos/mona-beesa-contest-outlined-logo.png";
import Beeatrice from "./../../Components/Media/Mascots/Beeatrice/Beeatrice-exploring.png";
import Beenta from "./../../Components/Media/Mascots/Beenta/Beenta-flying.png";
import BeentaPainting from "./../../Components/Media/Mascots/Beenta/Beenta-painting.png";
import BeeBee from "./../../Components/Media/Mascots/BeeBee/Beebee-greeting.png";


function Rules() {

    const location = useLocation();
    const from = location.state?.from;

    useEffect(() => {
        const submissions = document.getElementsByClassName("rules-more-details")[0];

        if (from === "submissions") {
            submissions.scrollIntoView({ behavior: 'smooth', block: 'start' });

        } else {
            window.scrollTo(0,0);
        }    
            
    }, []);


    return (      
        <div style={{position: "relative", zIndex: "4"}}>
            <div className="background-image-rules">
                <div className="background-image-rules-filling">
                    <div className="background-image-rules-filling">

                    </div>
                </div>
            </div>

            <div style={{position: "relative", top: "", zIndex: "5", paddingTop: "60%"}}>
                
                <div className="rules-container" >
                
                    <div className="rules-rules">
                        <h1>Process</h1>

                        <p>
                            1- Submit your art based on the given theme. 
                            <br /><br />
                            2 - Ten preselected drawings undergo a voting process on Facebook, Instagram and X.
                            <br /><br />
                            3 - Winners are announced!
                            <br /> <br />
                            Submissions end on <span style={{color: "rgba(255, 0, 0, 1)", fontWeight: "700"}}>August 31st</span>.
                        </p>
                    </div>

                    <div className="rules-theme">    
                            <h1>Theme</h1>

                            <p style={{width: "fit-content", fontStyle: "italic", fontWeight: "500"}}>Beeatrice, Beenta and BeeBee at the Olympic Games.</p>

                            <div className="rules-theme-images">
                                <img src={Beeatrice} />
                                <img src={Beenta} />
                                <img src={BeeBee} style={{width: "100px"}}/>
                            </div>  
                    </div>

                    <div className="rules-details">
                        <div className="details-details" style={{marginBottom: "70px"}}>
                            <h1>Details</h1>
                            <h3>Arts:</h3>
                            <p>Contestants are encouraged to unleash their creativity in this drawing contest. Feel free to incorporate one main character, multiple characters, or even create new characters, and give them any roles or functions you desire. There are no limitations on artistic expression, so let your imagination run wild and create something truly unique!</p>
                            <p>You may draw in your own style using whatever suits you best, such as pencils, paints, digital tablets, pastels or any other preferred drawing tools and techniques. Feel Free!</p>
                            <p>
                                Aspect Ratio must be 1:1
                                <br />
                                of at least 800 x 800 pixels.
                                <br />
                                <br />
                                Recommended: <span style={{color: "rgba(17, 240, 1, .7)", fontWeight: "700"}}>1080 x 1080 pixels</span>
                            </p>
                        </div>

                        <div className="rules-more-details">
                            <h3>Submission:</h3>
                            <p>
                                To enter the contest, please provide the following information along with your artwork:
                                <br /><br />
                                Name (last name not publicly shown)<br />
                                Pseudonym (if desired)<br />
                                Email address<br />
                                Country<br />
                                City<br /><br />
                                Submit your entry via email to: <br /> <span style={{color: "rgba(17, 240, 1, .7)"}}>submission.beexplorer@gmail.com</span>
                            </p>

                            <h3>Entry Deadline:</h3>
                            <p>
                                Entries must be submitted by june 15th.
                            </p>

                            <h3>Eligibility:</h3>
                            <p>
                                The contest is open to all participants.
                            </p>

                            <h3>Judging Criteria:</h3>
                            <p>
                                Entries will be judged based on the following criteria:<br /><br />

                                Winner: Best overall artwork.<br />
                                Honorable Mentions: Most Creative, Funniest, Artistic Skill.
                            </p>
                            
                            <h3>Prizes:</h3>
                            <p>
                                1st Place: $300
                                <br /><br />
                                2nd: $100
                                <br /><br />
                                3rd: $75
                            </p>

                            <h3>Ownership and Usage Rights:</h3>
                            <p>
                                By entering the contest, participants agree that the contest organizer will have rights to use or reproduce the artwork for promotional purposes and personal use. Ownership rights are shared with the participant.
                            </p>
                            
                            <h3>Terms and Conditions:</h3>
                            <p>
                                Participants must adhere to the following terms and conditions:<br /><br />

                                No plagiarism<br />
                                No use of artificial intelligence (AI)<br />
                                No inappropriate content
                            </p>

                            <h3>Contact:</h3>
                            <p>For any inquiries or assistance, please contact us at contact.beexplorer@gmail.com</p>

                            <h3 className="rules-details-punchline">Good Luck to all Participants!!</h3>
                            <img src={BeentaPainting} style={{width: "120px"}}/>
                            <img src={logo} alt="Logo" className="logo-title-contest" />
                        </div>
                    </div>
                </div>    

                <BackButton 
                    image={backImage} 
                    width={200} 
                    height={200} 
                    unit="px"
                    backToPage="Back to contest"
                    backTo="/contest"
                    fontFamily="pacifico"
                    fontSize="1.5rem"
                />

            </div> 
        </div>            
    
    );
};

export default Rules;
        
        