import { useState, useEffect, useReducer } from "react";
import { NavLink } from "react-router-dom";

import "./Home.css";

import Subscribe from "../Components/Utils/Subscribe";
import SlideShow from "../Components/UI/Slide-show";

import beeatriceExploring from "./../Components/Media/Mascots-reversed/Beeatrice/Beeatrice-exploring-bee-on-pink-flower.png";
import beeatriceReversed from "./../Components/Media/Mascots-reversed/Beeatrice/Beeatrice-greeting.png";
import beeatriceReading from "./../Components/Media/Mascots/Beeatrice/Beeatrice-reading-news-with-bee.png";
import beeatriceDrinking from "./../Components/Media/Mascots/Beeatrice/Beeatrice-tea.png";
import beeatriceEating from "./../Components/Media/Mascots-reversed/Beeatrice/Beeatrice-cookie-eyes-closed.png";
import beebee from "./../Components/Media/Mascots/BeeBee/Beebee-greeting.png";

import beehive from "./../Components/Media/beehive-home.png";
import logo from "./../Components/Media/beexplorer-logo.png";
import contest from "./../Components/Media/slide-show/drawing-contest-x-full-link.png";
import dykFoodProduced from "./../Components/Media/slide-show/dyk-food-produced.png";
import dyk from "./../Components/Media/slide-show/dyk.png";
import news from "./../Components/Media/slide-show/breaking-news-ontario-beesaster.gif";
import babeeliciousAd from "./../Components/Media/slide-show/Babeelicious-ad.png";

import weakybeedia from "./../Components/Media//Home/Presentations/weakybeedia.png";
import bbsNews from "./../Components/Media//Home/Presentations/bbs-news.png";
import babeelicious from "./../Components/Media/Home/Presentations/babee-licious.png";
import monaBeesa from "./../Components/Media/Home/Presentations/mona-beesa.png";
import beeXShop from "./../Components/Media/Home/Presentations/beexshop-coming-soon.png";
import ad from "./../Components/Media/Shop/beexshop-homepage-ad.gif";




function Home(props) {
    const [windowWidth, setWindowWidth] =useState(window.innerWidth);


    const slides = [
        //Timing of animated gif to be shared among static gifs. 
        // Transition duration to be considered if any.
        {
            url: contest,
            to: "contest",
            href: "",
            duration: 4300
        },
        {
            url: babeeliciousAd,
            to: "main-recipes/recipes/Honey%20Chocolate%20Brownie",
            href: "",
            duration: 4300
        },
        {
            url: dyk,
            to: "did-you-know",
            href: "",
            duration: 4000
        },
        {
            url: dykFoodProduced,
            to: "did-you-know",
            href: "",
            duration: 4000
        },
        {
            url: news,
            to: "bbs-news/news/Bee-Saster%20in%20Ontario:%205%20Million%20Bees%20Unleashed!😱",
            href: "",
            duration: 4000
            //8300
        }
    ];


    function handleResize() {
        setWindowWidth(window.innerWidth);
    };


    useEffect(() => {
        window.scrollTo(0,0);


        window.addEventListener('resize', handleResize);


        return () => {
            window.removeEventListener('resize', handleResize);
        };

    }, []);


    
    if (windowWidth >= 750) {
        return (

            <div style={{position: "relative" }}>
                
                <div className="home-container" style={{margin: "40px auto", paddingTop: "10px"}}>
                    
                    <img rel="preload" src={beehive} className="beehive-home" alt="Sweet Castle"/>

                    <div className="logo-home-container">
                        <img src={logo} className="logo-home"/>
                    </div>


                    <div className="welcome-news-feed">
                        <div className="mascot-welcome-container">
                            <p className="mascott-message">              
                                Hello, I'm Beeatrice! Welcome to Beexplorer!!<br />
                                Where you can discover the entire Bee World in one place!<br />
                                Take a tour around our beehive, "Sweet Castle",<br />
                                and let's embark on our journey around the world!!<br />
                                YouTube Channel coming soon!
                            </p>

                            <img rel="preload" src={beeatriceReversed} className="mascot-welcome" alt="mascot"/>
                        </div>
                            
                    </div>

                    
                    <SlideShow slides={slides} parentWidth={700} imageHeight={500} unit={"px"} heightUnit={"px"} />
                    
                    
                    <div className="explore-container-title">
                        <h1>Be Explorer</h1>
                    </div>

                    
                    <div className="presentation-mascot-container">
                        
                        <div className="mascot-presentation-container">
                            <p className="mascott-message">              
                                "Get ready before we go exploring"
                            </p>

                            <img rel="preload" src={beeatriceExploring} className="mascot-welcome-lg" alt="mascot"/>
                        </div>
                        
                        <NavLink to="all-articles" activeclassname="active">
                            <div className="presentation-container">
                                <img rel="preload" src={weakybeedia} className="presentation" alt="Weakybeedia"/>
                            </div>
                        </NavLink>
                    </div>

                    <div className="presentation-mascot-container">
                        <NavLink to="bbs-news" activeclassname="active">
                            <div className="presentation-container">
                                <img rel="preload" src={bbsNews} className="presentation" alt="BBS News"/>
                            </div>
                        </NavLink>

                        <div className="mascot-presentation-container">
                            <p className="mascott-message">              
                                "Get the news from Beebee"
                            </p>

                            <img rel="preload" src={beeatriceReading} className="mascot-welcome-lg" alt="mascot"/>
                        </div>
                    
                    </div>


                    <div className="presentation-mascot-container">
                        
                        <div className="mascot-presentation-container">
                            <p className="mascott-message">              
                                "Follow Grandma's recipes to impress your guests <br />
                                or your own stomach"
                            </p>

                            <img rel="preload" src={beeatriceEating} className="mascot-welcome" alt="mascot"/>
                        </div>
                        
                        <NavLink to="main-recipes" activeclassname="active">
                            <div className="presentation-container">
                                <img rel="preload" src={babeelicious} className="presentation" alt="babeelicious"/>
                            </div>
                        </NavLink>
                        
                    </div>


                    <div className="presentation-mascot-container">
                        <NavLink to="gallery" activeclassname="active">
                            <div className="presentation-container">
                                <img rel="preload" src={monaBeesa} className="presentation" alt="Mona Beesa"/>
                            </div>
                        </NavLink>
                        
                        <div className="mascot-presentation-container">
                            <p className="mascott-message">              
                                "Enjoy Beenta's and fellow explorers' arts"
                            </p>

                            <img rel="preload" src={beeatriceDrinking} className="mascot-welcome" alt="mascot"/>
                        </div>
                        
                    </div>
                    
                    <a href="https://beeXshop.etsy.com" target="_blank">
                        <img rel="preload" src={beeXShop} className="presentation-center" alt="BeeXshop"/>
                    </a>

                    <a href="https://beeXshop.etsy.com" target="_blank"><img rel="preload" src={ad} className="presentation-center-ad" alt="BeeXshop AD"/></a>


                    <div className="subscribe-container">
                        <div className="subscribe-container-title">
                            <h1>Stay Connected to the Hive</h1>
                        </div>
                    

                        <p className="mascott-message">              
                                Wherever you go, receive Updates, News, Information, Promotions!! 
                        </p>
                        <img rel="preload" src={beebee} className="mascot-subscribe-welcome" alt="mascot"/>

                        <Subscribe
                            functions={props.functions}
                        />

                    </div>
                    
                </div>

    
            </div>
        );

    } else {
        return (
            <div style={{position: "relative", fontSize: ".7rem" }}>
                
                <div className="home-container" style={{margin: "40px auto", paddingTop: "10px"}}>
                    
                    <img rel="preload" src={beehive} className="beehive-home-750" alt="Sweet Castle"/>

                    <div className="logo-home-container-750">
                        <img rel="preload" src={logo} className="logo-home-750" alt="logo"/>
                    </div>

                    <div className="welcome-news-feed">
                        <div className="mascot-welcome-container-750">
                            <p className="mascott-message">              
                                Hello, I'm Beeatrice!<br /> Welcome to Beexplorer!!<br />
                                Where you can discover<br /> the entire Bee World in one place!<br />
                                Take a tour around our beehive,<br /> "Sweet Castle",<br />
                                and let's embark on our journey<br /> around the world!!<br />
                                YouTube Channel coming soon!
                            </p>

                            <img rel="preload" src={beeatriceReversed} className="mascot-welcome-750" alt="mascot"/>
                        </div>

                        <SlideShow slides={slides} parentWidth={90} imageHeight={750} unit={"vw"} heightUnit={"px"} />
                        

                        <div className="slide-show-home-container-750"></div>
                            
                    </div>
                    
                    <div className="explore-container-title-750">
                        <h1>Be Explorer</h1>
                    </div>

                    
                    <div className="presentation-mascot-container-750" style={{marginTop: "50px"}}>
                        
                        <div className="mascot-presentation-container-750">
                            <p className="mascott-message">              
                                "Get ready<br /> before we go exploring"
                            </p>

                            <img rel="preload" src={beeatriceExploring} className="mascot-welcome-lg" alt="mascot"/>
                        </div>
                        
                        <NavLink to="all-articles" activeclassname="active">
                            <div className="presentation-container-750">
                                <img rel="preload" src={weakybeedia} className="presentation" alt="Weakybeedia"/>
                            </div>
                        </NavLink>
                        
                    </div>


                    <div className="presentation-mascot-container-750">

                        <div className="mascot-presentation-container-750">
                            <p className="mascott-message">              
                                "Get the news from Beebee"
                            </p>

                            <img rel="preload" src={beeatriceReading} className="mascot-welcome-lg" alt="mascot"/>
                        </div>

                        <NavLink to="bbs-news" activeclassname="active">
                            <div className="presentation-container-750">
                                <img rel="preload" src={bbsNews} className="presentation" alt="BBS News"/>
                            </div>
                        </NavLink>
                    
                    </div>


                    <div className="presentation-mascot-container-750">
                        
                        <div className="mascot-presentation-container-750">
                            <p className="mascott-message">              
                                "Follow Grandma's recipes<br /> to impress your guests <br />
                                or your own stomach"
                            </p>

                            <img rel="preload" src={beeatriceEating} className="mascot-welcome" alt="mascot"/>
                        </div>
                        
                        <NavLink to="main-recipes" activeclassname="active">
                            <div className="presentation-container-750">
                                <img rel="preload" src={babeelicious} className="presentation" alt="babeelicious"/>
                            </div>
                        </NavLink>
                        
                    </div>


                    <div className="presentation-mascot-container-750">
                        
                        <div className="mascot-presentation-container-750">
                            <p className="mascott-message">              
                                "Enjoy Beenta's and<br /> fellow explorers' arts"
                            </p>

                            <img rel="preload" src={beeatriceDrinking} className="mascot-welcome" alt="mascot"/>
                        </div>

                        <NavLink to="gallery" activeclassname="active">
                            <div className="presentation-container-750">
                                <img rel="preload" src={monaBeesa} className="presentation" alt="Mona Beesa"/>
                            </div>
                        </NavLink>
                        
                    </div>
                    
                    <a href="https://beeXshop.etsy.com" target="_blank">
                        <img rel="preload" src={beeXShop} className="presentation-center-750" alt="BeeXshop"/>
                    </a>

                    <a href="https://beeXshop.etsy.com" target="_blank"><img rel="preload" src={ad} className="presentation-center-ad" alt="BeeXshop AD"/></a>


                    <div className="subscribe-container-750">
                        <div className="subscribe-container-title-750">
                            <h3>Stay Connected to the Hive</h3>
                        </div>
                    

                        <p className="mascott-message">              
                                Wherever you go, receive Updates, News, Information, Promotions!! 
                        </p>
                        <img rel="preload" src={beebee} className="mascot-subscribe-welcome" alt="mascot"/>

                        <Subscribe />

                    </div>                 
                </div>
            </div>
        );
    }; 
};

export default Home;