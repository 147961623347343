import { createContext } from "react";



export const FunctionsContext = createContext({});





