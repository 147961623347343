import { useEffect } from "react";

import WeakyCard from "../../Components/UI/Weakybeedia/Weaky-card";

import logo from "./../../Components/Media/logos/weakybeedia-logo-silver-green.png";
import Beeatrice from "./../../Components/Media/Mascots/Beeatrice/Beeatrice-greeting-with-book.png";
import honeybee from "./../../Components/Media/Weakybeedia/honeybee-dark-room.jpg";
import differentBees from "./../../Components/Media/Weakybeedia/different-types-bees.jpg";

import backImage from "./../../Components/Media/library.jpg";
import BackButton from "./../../Components/UI/Buttons/Back-button"

import honeybeeIcon from "./../../Components/Media/Icons/honeybee-icon.png";
import differentBeesIcon from "./../../Components/Media/Icons/different-bees-icon.png";


function AllArticles() {

  useEffect(() => {
    window.scrollTo(0,0);
  }, []);


  return (

    <div className="background-image-articles-all">

      <div style={{position: "relative", paddingTop: "50px"}}>
        
        <img src={logo} alt="Logo" className="logo-title-xl" />

        <div>
          <p className="welcome-message mascott-message">
                "Bee Articles"
          </p>
        </div>
        <img src={Beeatrice} alt="Beeatrice" className="mascott-title" />
        
        <div className="weaky-card-container">

          <WeakyCard
            link="bees"
            image={differentBees}
            title="Different Types of Bees"
            description="From honeybees to bumblebees, hornets, and wasps, explore their unique behaviors and pivotal roles in ecosystems and biodiversity."     
            icon={differentBeesIcon}
            brightness="low-brightness"
          />

          <WeakyCard
            link="honeybees"
            image={honeybee}
            title="HoneyBees Special"
            description="Embark into the captivating world of honeybees, exploring their intricate behaviors, essential roles, and relationship with the human species."    
            icon={honeybeeIcon}
            brightness="low-brightness"
          />

        </div>

        <BackButton 
                image={backImage} 
                width={200} 
                height={200} 
                unit="px"
                backToPage="Back to main menu"
                backTo="/all-articles"
                fontFamily="eb garamond"
                fontSize="1.3rem"
            />
        
      </div>  
    </div>
  );
};

export default AllArticles;