import { useEffect } from "react";


function TermsConditions() {

    useEffect(() => {
        window.scrollTo(0,0);

    }, []);


    return (
        <div className="legals">
            <h2>Terms and Conditions</h2>

                <p>Welcome to beexplorer.org! These terms and conditions outline the rules and regulations for the use of our website.

                By accessing this website, we assume you accept these terms and conditions in full. Do not continue to use beexplorer.org if you do not accept all of the terms and conditions stated on this page.

                The following terminology applies to these Terms and Conditions, Privacy Statement, and Disclaimer Notice and any or all Agreements: "Client", "You", and "Your" refers to you, the person accessing this website and accepting the Company's terms and conditions. "The Company", "Ourselves", "We", "Our", and "Us", refers to our Company. "Party", "Parties", or "Us", refers to both the Client and ourselves, or either the Client or ourselves.</p>

            <h2>Cookies</h2>

                <p>We employ the use of cookies. By accessing beexplorer.org, you agreed to use cookies in agreement with the beexplorer.org's Privacy Policy.

                Most interactive websites use cookies to let us retrieve the user's details for each visit. Cookies are used by our website to enable the functionality of certain areas to make it easier for people visiting our website. Some of our affiliate/advertising partners may also use cookies.</p>

            <h2>License</h2>

                <p>Unless otherwise stated, beexplorer.org and/or its licensors own the intellectual property rights for all material on beexplorer.org. All intellectual property rights are reserved. You may access this from beexplorer.org for your own personal use subjected to restrictions set in these terms and conditions.

                You must not:

                Republish material from beexplorer.org
                Sell, rent, or sub-license material from beexplorer.org
                Reproduce, duplicate or copy material from beexplorer.org
                Redistribute content from beexplorer.org
                This Agreement shall begin on the date hereof.</p>

            <h2>Content Usage and Attribution</h2>

                <p>Users are permitted to use the content (text, images, videos, etc.) provided on beexplorer.org for personal, non-commercial purposes only. However, any reproduction, distribution, or modification of the content must include proper attribution to beexplorer.org as the original source. Attribution should prominently display the website's name, URL, and any other information deemed necessary for clear identification.

                Commercial use of the content, including but not limited to republishing, selling, or licensing, is strictly prohibited without prior written consent from beexplorer.org.</p>

            <h2>Hyperlinking to our Content</h2>

                <p>The following organizations may link to our Website without prior written approval:

                Government agencies;
                Search engines;
                News organizations;
                Online directory distributors may link to our Website in the same manner as they hyperlink to the Websites of other listed businesses; and
                System wide Accredited Businesses except soliciting non-profit organizations, charity shopping malls, and charity fundraising groups which may not hyperlink to our Web site.
                These organizations may link to our home page, to publications or to other Website information so long as the link: (a) is not in any way deceptive; (b) does not falsely imply sponsorship, endorsement, or approval of the linking party and its products and/or services; and (c) fits within the context of the linking party’s site.

                We may consider and approve other link requests from the following types of organizations:

                commonly-known consumer and/or business information sources;
                dot.com community sites;
                associations or other groups representing charities;
                online directory distributors;
                internet portals;
                accounting, law and consulting firms; and
                educational institutions and trade associations.
                We will approve link requests from these organizations if we decide that: (a) the link would not make us look unfavorably to ourselves or to our accredited businesses; (b) the organization does not have any negative records with us; (c) the benefit to us from the visibility of the hyperlink compensates the absence of beexplorer.org; and (d) the link is in the context of general resource information.

                These organizations may link to our home page so long as the link: (a) is not in any way deceptive; (b) does not falsely imply sponsorship, endorsement or approval of the linking party and its products or services; and (c) fits within the context of the linking party’s site.

                If you are one of the organizations listed in paragraph 2 above and are interested in linking to our website, you must inform us by sending an e-mail to beexplorer.org. Please include your name, your organization name, contact information as well as the URL of your site, a list of any URLs from which you intend to link to our Website, and a list of the URLs on our site to which you would like to link. Wait 24 hours to 1 week for a response.

                Approved organizations may hyperlink to our Website as follows:

                By use of our corporate name; or
                By use of the uniform resource locator being linked to; or
                By use of any other description of our Website being linked to that makes sense within the context and format of content on the linking party’s site.
                No use of beexplorer.org's logo or other artwork will be allowed for linking absent a trademark license agreement.</p>

            <h2>iFrames</h2>

                <p>Without prior approval and written permission, you may not create frames around our Webpages that alter in any way the visual presentation or appearance of our Website.

                Content Liability

                We shall not be held responsible for any content that appears on your Website. You agree to protect and defend us against all claims that are rising on your Website. No link(s) should appear on any Website that may be interpreted as libelous, obscene, or criminal, or which infringes, otherwise violates, or advocates the infringement or other violation of, any third party rights.</p>

            <h2>Reservation of Rights</h2>

                <p>We reserve the right to request that you remove all links or any particular link to our Website. You approve to immediately remove all links to our Website upon request. We also reserve the right to amend these terms and conditions and its linking policy at any time. By continuously linking to our Website, you agree to be bound to and follow these linking terms and conditions.

                Removal of links from our website

                If you find any link on our Website that is offensive for any reason, you are free to contact and inform us at any moment. We will consider requests to remove links but we are not obligated to or so or to respond to you directly.

                We do not ensure that the information on this website is correct, we do not warrant its completeness or accuracy; nor do we promise to ensure that the website remains available or that the material on the website is kept up to date.</p>

            <h2>Disclaimer</h2>

                <p>To the maximum extent permitted by applicable law, we exclude all representations, warranties, and conditions relating to our website and the use of this website. Nothing in this disclaimer will:

                limit or exclude our or your liability for death or personal injury;
                limit or exclude our or your liability for fraud or fraudulent misrepresentation;
                limit any of our or your liabilities in any way that is not permitted under applicable law; or
                exclude any of our or your liabilities that may not be excluded under applicable law.
                The limitations and prohibitions of liability set in this Section and elsewhere in this disclaimer: (a) are subject to the preceding paragraph; and (b) govern all liabilities arising under the disclaimer, including liabilities arising in contract, in tort, and for breach of statutory duty.

                As long as the website and the information and services on the website are provided free of charge, we will not be liable for any loss or damage of any nature.</p>
        </div>
    );
};

export default TermsConditions;