import { useState } from "react";
import { NavLink } from "react-router-dom";

import SubscribeFooter from "./../Utils/Subscribe-footer";

import "./Footer.css";

import runningBeea from "./../Media/Mascots-reversed/Beeatrice/Beeatrice-exploring.png";
import runningHoneybee from "./../Media/Gifs/bee-蜜蜂.gif";


function Footer() {
    

    return (
        <div style={{marginTop: "auto"}}>
            <footer className="new_footer_area bg_color">

                <div className="new_footer_top">
                    <div className="container">
                        <div className="row">

                            <div className="col-lg-3 col-md-6">
                                <div className="f_widget company_widget wow fadeInLeft" style={{visibility: "visible", animationDelay: "0.2s", animationName: "fadeInLeft"}}> 
                                    <h3 className="f-title f_600 t_color f_size_18">Stay Connected</h3>
                                    <p>Don’t miss any updates from the hive!</p>
                                    <SubscribeFooter />
                                </div>
                            </div>

                            {/* <div className="col-lg-3 col-md-6">
                                <div className="f_widget about-widget pl_70 wow fadeInLeft" style={{visibility: "visible", animationDelay: "0.4s", animationName: "fadeInLeft"}}>
                                    <h3 className="f-title f_600 t_color f_size_18">Download</h3>
                                    <ul className="list-unstyled f_list">
                                        <li><a href="#">Company</a></li>
                                        <li><a href="#">Android App</a></li>
                                        <li><a href="#">ios App</a></li>
                                        <li><a href="#">Desktop</a></li>
                                        <li><a href="#">Projects</a></li>
                                        <li><a href="#">My tasks</a></li>
                                    </ul>
                                </div>
                            </div> */}

                            <div className="col-lg-3 col-md-6">
                                <div className="f_widget about-widget pl_70 wow fadeInLeft" style={{visibility: "visible", animationDelay: "0.6s", animationName: "fadeInLeft"}}>
                                    <h3 className="f-title f_600 t_color f_size_18">Help</h3>
                                    <ul className="list-unstyled f_list">
                                        {/* <li><a href="#">FAQ</a></li> */}
                                        <li><NavLink to="/contact">Contact</NavLink></li>
                                        <li><NavLink to="/terms&conditions">Terms & conditions</NavLink></li>
                                        {/* <li><a href="#">Reporting</a></li> */}
                                        {/* <li><a href="#">Documentation</a></li> */}
                                        <li><NavLink to="/support-policy">Support Policy</NavLink></li>
                                        <li><NavLink to="/privacy-policy">Privacy</NavLink></li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6">
                                <div className="f_widget social-widget pl_70 wow fadeInLeft" style={{visibility: "visible", animationDelay: "0.8s", animationName: "fadeInLeft"}}>
                                    <h3 className="f-title f_600 t_color f_size_18">Join Us</h3>
                                    <div className="f_social_icon">
                                        <a href="https://x.com/beexmedia" className="fab fa-x-twitter" target="_blank"></a>
                                        <a href="https://www.instagram.com/beexmedia/" className="fab fa-instagram" target="_blank"></a>
                                        <a href="https://www.tiktok.com/@beexmedia_" className="fab fa-tiktok" target="_blank"></a>
                                        {/* <a href="#" className="fab fa-youtube"></a> */}
                                    </div>
                                </div>
                            </div>


                        </div>
                    </div>

                    <div className="footer_bg">
                        <div className="footer_bg_one"></div>
                        <div className="footer_bg_two"></div>
                        <div className="footer_bg_three"></div>
                        <div className="footer_bg_four">
                            <img src={runningBeea} className="runningBeea"/>
                            <img src={runningHoneybee} className="runningHoneybee"/>
                        </div>
                    </div>
                </div>


                <div className="footer_bottom">
                    <p className="">© Beexplorer 2024 All rights reserved.</p>
                
                    {/* <p>Footer made with <i className="icon_heart"></i><a href="http://cakecounter.com" target="_blank">CakeCounter</a></p> */}
                </div>

            </footer>
        </div>
    );
};

export default Footer;