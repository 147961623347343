import { useState, useEffect } from "react";
import { useParams, useLoaderData } from "react-router-dom";
import Markdown from "react-markdown";
import rehypeRaw from "rehype-raw";

import backImage from "./../../../Components/Media/kitchen.jpg";
import BackButton from "./../../../Components/UI/Buttons/Back-button";

import "./Post.css";
import "./PostRecipes.css";

import BaBee from "./../../../Components/Media/Mascots/Babee/Babee-cooking.png";
import postsRecipes from "./../../../Components/Data-permanent/Recipes/postRecipes/postrecipes.json";


const Post = () => {
    const [post, setPost] = useState("");


    const params = useParams();


    const fetchPost = async () => {

        try {
            let wantedArticle;

            if (params.article === "recipes") {
                wantedArticle = postsRecipes.find(article => article.title === params.post);
            } else if (params.article === "advices") {
                // wantedArticle = postsAdvices.find(article => article.title === params.post);
            }

            setPost(wantedArticle);

        } catch (error) {
            console.error('Error fetching post:', error);
        }
    };


    useEffect(() => {
        window.scrollTo(0,0);
    
        fetchPost();

    }, []);

    return (
        <div style={{position: "relative"}}>
            <div className="background-image-postRecipes"></div> 
                <div><p></p></div>

                <div className="post-container">
                    <div className="post-title-content">

                        <div style={{textAlign: "center"}}>
                            <h1 className="post-title-recipes">{post.title}
                                <img src={BaBee} style={{width: "130px", height: "100%", display: "inline-block", verticalAlign: "middle"}}/>
                            </h1>
                            
                        </div>

                        <img src={post.mainImage} className="post-mainImage-recipes" />

                        {post.servings && <p className="serving-time">
                             <span className="serving-time-key">Servings: </span>{post.servings}. <br />
                             <span className="serving-time-key">Prep: </span>{post.prep}. <br />
                             <span className="serving-time-key">Baking: </span>{post.baking}. <br />
                             <span className="serving-time-key">Cooling: </span>{post.cooling}.
                        </p> }

                        <br />

                        <div className="post-content-recipes">
                            <div className="ingredients">
                                <p className='ingredients-title'>Ingredients:</p>
                                <br />
                                <Markdown rehypePlugins={[rehypeRaw]} className="ingredients-list" >{post.ingredients}</Markdown>
                                <img src="https://beexmedia.web.app/media/icons/ingredient-table.png" style={{width: "100%"}} />
                            </div>

                            <br />

                            <div className="directions">
                                <p className='directions-title'>Directions:</p>
                                <br />
                                <Markdown rehypePlugins={[rehypeRaw]}>{post.directions}</Markdown>
                            </div>
                        </div>

                        <br />
                        <img src={post.mainImage} className="post-mainImage-recipes" />

                    </div>

                    <div className="mascott-punchline-container">
                        <img src={BaBee} className="mascott-punchline"/>
                        <p className="post-punchline mascott-message">"{post.punchline}"</p>
                    </div>

                    <br />

                    <img src={post.lastImage} className="post-lastImage-recipes" />

                    <div>
                        <div class="post-author-date-recipes"><p>{post.author}</p></div>
                        
                        <div class="post-author-date-recipes"><p>{post.date}</p></div>
                    </div>


                    <BackButton 
                        image={backImage} 
                        width={200} 
                        height={200} 
                        unit="px"
                        backToPage="Back to recipes"
                        backTo="/main-recipes/recipes"
                        fontFamily="poppins"
                        fontSize="1.15rem"
                    />

                </div>
            
        </div>
        
    );
};

export default Post;