import { useState, useEffect, useRef, useCallback } from "react";
import { NavLink } from "react-router-dom";

import "./Slide-show.css";


const SlideShow = ({slides, parentWidth, imageHeight, unit, heightUnit}) => {
    const timerRef = useRef(null);
    const [currentIndex, setCurrentIndex] = useState(0);


    const previousSlide = () => {
        const isFirstSlide = currentIndex === 0;
        const newIndex = isFirstSlide ? slides.length - 1 : currentIndex - 1;
        setCurrentIndex(newIndex);
    };

    const nextSlide = useCallback(() => {
        const isLastSlide = currentIndex === slides.length - 1;
        const newIndex = isLastSlide ? 0 : currentIndex + 1;
        setCurrentIndex(newIndex);
    });

    const selectedSlide = (slideIndex) => {
        setCurrentIndex(slideIndex);
    };


    const startTimer = useCallback(() => {
        timerRef.current = setTimeout(() => {
            nextSlide();
        }, slides[currentIndex].duration === undefined ? 2000 : slides[currentIndex].duration);
    });

    const stopTimer = () => {
        clearTimeout(timerRef.current);
    };


    useEffect(() => {
        if (timerRef.current) {
            clearTimeout(timerRef.current);
        }

       startTimer();

        return () => {
            stopTimer();
        };
    }, [currentIndex, startTimer]);


    return (
        <div className="slides-container">
            <div className="slide-container">
                <div className="left-arrow slide-arrow" onClick={previousSlide}>🌜</div>
                <div className="right-arrow slide-arrow" onClick={nextSlide}>🌛</div>
                
                <div className="slide-image-overflow-container">
                    <div 
                        className="slide-image-container"
                        style={{ 
                            width: `${(parentWidth * slides.length) + unit}`,
                            transform: `translateX(${-(currentIndex * parentWidth) + unit})`
                        }}
                        onMouseEnter={stopTimer} 
                        onMouseLeave={startTimer}
                    >
                        {slides.map((slide, slideIndex) => {
                            return (
                                <NavLink to={slide.to} >
                                    <div style={{ 
                                        width: `${parentWidth + unit}`}}>
                                        <div style={{height: "100%"}}>
                                            <img 
                                                rel="preload"
                                                key={slideIndex} className="slide-image" 
                                                src={slides[slideIndex].url}
                                                style={{maxHeight: `${imageHeight + heightUnit}`}}
                                                
                                            />
                                        </div>
                                    </div>
                                </NavLink>
                            );
                        })}
                    </div>
                </div>
            </div>

            <div className="slide-dot-container">
                {slides.map((slide, slideIndex) => {
                    if (slideIndex === currentIndex) {
                        return (
                            <div className="slide-dot-bigger" key={slideIndex} onClick={()=>selectedSlide(slideIndex)}>🌞</div>
                        );
                    } else {
                        return (
                            <div className="slide-dot" key={slideIndex} onClick={()=>selectedSlide(slideIndex)}>🌞</div>
                        );
                    }
                    
                })}
            </div>
        </div>
    );
};

export default SlideShow;