import { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { Dropdown } from "react-bootstrap";

import menu from "./../Media/Icons/icons8-menu-78.png";

import "./Navbar.css";

import logo from "./../Media/beexplorer-logo.png";

function Navbar() {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);


  function handleResize() {
    setWindowWidth(window.innerWidth);
  };

  useEffect(() => {
    window.scrollTo(0,0);

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
    
  }, []);


  if (windowWidth < 750) {
    return (
      <div className="nav-div">

        
        <div className="d-md-none"> 
          <Dropdown className="">
            <Dropdown.Toggle className="drop-toggle" variant="success" id="dropdown-basic">
              <img src={menu} style={{width: "25px", height: "25px"}} />
            </Dropdown.Toggle>

            <Dropdown.Menu className="dropped-menu">
    
              <Dropdown.Item href="">
                <div className="logo-nav-explorer">
                  <NavLink to="/" activeclassname="active2">
                    <img src={logo} alt="Logo" className="logo-nav" />
                  </NavLink>
                </div>
              </Dropdown.Item>


              <Dropdown.Item href="">
                <NavLink to="/" activeclassname="active" end={true}>Home</NavLink>
              </Dropdown.Item>

              <Dropdown.Item href="">
                <NavLink to="all-articles" activeclassname="active">Weakybeedia</NavLink>
              </Dropdown.Item>

              <Dropdown.Item href="">
                <NavLink to="bbs-news" activeclassname="active">BBS News</NavLink>
              </Dropdown.Item>

              <Dropdown.Item href="">
                <NavLink to="main-recipes" activeclassname="active">Recipes</NavLink>
              </Dropdown.Item>

              <Dropdown.Item href="">
                <a href="https://beeXshop.etsy.com" target="_blank">Shop</a> 
              </Dropdown.Item>

              <Dropdown.Item href="">
                <NavLink to="gallery" activeclassname="active">Gallery</NavLink>
              </Dropdown.Item>

              <Dropdown.Item href="">
                <NavLink to="contest" activeclassname="active" style={{ fontWeight: "500"}}>Contest</NavLink>
              </Dropdown.Item>

              {/* <Dropdown.Item href="">
                <a href="https://x.com/i/communities/1796255864921153763" target="_blank">Community</a>
              </Dropdown.Item> */}

              <Dropdown.Item href="">
                <NavLink to="save-the-bees" activeclassname="active">Ambeesadors</NavLink>
              </Dropdown.Item>

            </Dropdown.Menu>
          </Dropdown>
        </div>
       </div>
      );
  } else {

    return (

      <div className="nav-div">
        <nav className="navbar-explorer">
        <div className="logo-nav-explorer">
          <NavLink to="/">
            <img src={logo} alt="Logo" className="logo-nav" />
          </NavLink>
        </div>

        <div className="">
          <ul className="nav justify-content-center nav-pills">
            <li className="nav-item main-li-nav">
              <NavLink className="nav-link" className="nav-link" to="/" activeclassname="active" end={true}>Home</NavLink>
            </li>
            <li className="nav-item main-li-nav">
              <NavLink className="nav-link" to="all-articles" activeclassname="active">Weakybeedia</NavLink>
            </li>
            <li className="nav-item main-li-nav">
              <NavLink className="nav-link" to="bbs-news" activeclassname="active">BBS News</NavLink>
            </li>
            <li className="nav-item main-li-nav">
              <NavLink className="nav-link" to="main-recipes" activeclassname="active">Recipes</NavLink>
            </li>
            <li className="nav-item main-li-nav">
            <a className="nav-link" href="https://beeXshop.etsy.com" target="_blank">Shop</a>
            </li>
            <li className="nav-item main-li-nav">
              <NavLink className="nav-link" to="gallery" activeclassname="active">Gallery</NavLink>
            </li>
            <li className="nav-item main-li-nav">
              <NavLink className="nav-link" to="contest" activeclassname="active" style={{ fontWeight: "500"}}>Contest</NavLink>
            </li>
          </ul>
        </div>

        <div className="nav nav-pills justify-content-end">
            {/* <a className="nav-link nav-item" href="https://x.com/i/communities/1796255864921153763" target="_blank">Community</a> */}
          <NavLink className="nav-link nav-item" to="save-the-bees" activeclassname="active">Ambeesadors</NavLink>
        </div>
      </nav> 
    </div>
  );
  }

  
}

export default Navbar;
