import { useState, useCallback } from "react";
import "./Did-you-know-UI.css";

function DidYouKnowSlide(props) {
    const [currentIndex, setCurrentIndex] = useState(0);

    const selectedSlide = (slideIndex) => {
        setCurrentIndex(slideIndex);
    };


    return (
        <div key={props.fact.id || props.index} className="dyk-main-container">
    
            <div className="dyk-image-main-container">
                
                <div className="dyk-image-container">
                    <div className="dyk-image-overflow-container">
                        
                        <div className="dyk-image-container"
                            style={{
                                width: `${ props.divWidth * props.fact.images.length}${props.unit}`,
                                transform: `translateX(${-(currentIndex * props.divWidth)}${props.unit})`,
                                transition: 'transform 0.5s ease-out'
                            }}  
                        >
                            
                            {props.fact.images.map((image, imgIndex) => (
                    
                                <div style={{ 
                                    width: `${props.divWidth + props.unit}`}}>
                                    <div style={{height: "100%"}}>
                                        <img key={imgIndex} className="dyk-image" src={props.fact.images[imgIndex].src} alt={image.alt || "Fact image"}  />
                                    </div>
                                </div>
                            ))}
                                
                        </div>
                    </div>
                </div>
            </div>

            <div style={{margin: ""}}>
                <div className="slide-dot-container-dyk">
                    {props.fact.images.map((slide, slideIndex) => {
                        if (slideIndex === currentIndex) {
                            return (
                                <div className="silde-dot-individual-container">
                                    <div className="slide-dot-bigger-dyk" key={slideIndex} onClick={()=>selectedSlide(slideIndex)}>🌞</div>
                                </div>
                            );
                        } else {
                            return (
                                <div className="silde-dot-individual-container">
                                    <div className="slide-dot-dyk" key={slideIndex} onClick={()=>selectedSlide(slideIndex)}>🌞</div>
                                </div>
                            );
                        }
                        
                    })}
                </div>
            </div>
    
        </div>
    );
};

export default DidYouKnowSlide;