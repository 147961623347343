import { useState, useEffect } from "react";

import NewsCard from "../../Components/UI/BBS-News/News-card";
import logo from "./../../Components/Media/logos/bbs-logos/bbs-logo.png";
import BeeBee from "./../../Components/Media/Mascots/BeeBee/Beebee-reporting.png";
import BBSNewsCover from "./../../Components/Media/BBS-news/BBS-newspaper-cover.png";
import BBSNewsInside from "./../../Components/Media/BBS-news/BBS-newspaper-inside.png";
import BBSInterviewCover from "./../../Components/Media/BBS-news/BBS-interview-cover.png";
import BBSInterviewInside from "./../../Components/Media/BBS-news/BBS-interview-inside.png";

import "./BBS-News.css";


function AllArticles() {
  const [showLogo, setShowLogo] = useState(false);
  const [savedTime, setSavedTime] = useState(0);

  
  useEffect(() => {
    window.scrollTo(0,0);

    // const storedTimestamp = localStorage.getItem("pageLoadTimestamp");
    // console.log("storedTimeStamp:" +storedTimestamp);

    const refreshedTime = new Date().getTime();
    const storedTime = localStorage.getItem("pageLoadTimeAnimation");

    const elapsedTime = refreshedTime - storedTime;
    
    
    if (storedTime === null ) {

      setShowLogo(true);
      const currentTime = new Date().getTime();
      localStorage.setItem('pageLoadTimeAnimation', currentTime);

    } else if (elapsedTime > 1800000 || elapsedTime < 5000) {

      setShowLogo(true);
      const currentTime = new Date().getTime();
      localStorage.setItem('pageLoadTimeAnimation', currentTime);

    } else {
      setShowLogo(false);
    }

    // console.log("storedTime: " + storedTime + ", refreshedTime: " + refreshedTime + ", elapsedTime: " + elapsedTime);
    console.log(localStorage);


    // if (storedTimestamp === null) {
      
    //   const currentTime = new Date().getTime();
    //   localStorage.setItem('pageLoadTimestamp', currentTime);
    // }  else {
      
    //   const storedTime = parseInt(storedTimestamp, 10);
    //   const currentTime = new Date().getTime();
    //   const elapsedTime = currentTime - storedTimestamp;
    //   console.log("currentTime: " + currentTime + ", storedTime: " + storedTime + ", elapsedTime: " + elapsedTime);

      
    //   if (elapsedTime > 60000 || elapsedTime < 10000) { // 3600000 milliseconds = 1 hour
    //     setShowLogo(true);
    //   } else {
    //     setShowLogo(false);
    //   }

    // }

    const timeout = setTimeout(() => {
      setShowLogo(false);
    }, 0);

    return () => {
      clearTimeout(timeout);
    };

  }, []);



  if (showLogo === true) {

    return (
      <div className="background-image-bbs-news">

        <div style={{ display: "inline-block", margin: "50px auto", position: "relative"}}>
            <img rel="preload" src={logo} alt="Logo" className="logonimation logo-animation" />
            <img rel="preload" src={BeeBee} alt="BeeBee" className="logonimation mascott-animation" /> 
        </div>

    </div>
    );

  } else {

    return (
      <div className="background-image-bbs-news">
          
        
        
        <div style={{}}>
          <div>
            <img src={logo} alt="Logo" className="logo-title" />
            
            <p className="welcome-message mascott-message">
              "Hiiiii, I'm BeeBee!
                <br />
              I report the news!! keekee&#128513;
                <br />
              I'll cover interesting events about the bee world, seen through my eyes!
              <br />
              My grandma aka "The Retired Queen" doesn't want me to. She said I have to soon start nursing my younger sisters, which I love...
              <br />
              But, I want to see the World! I want to cover it! I want To be brave and go on adventures like my daring older Sisters, Beeatrice and Beenta!!!
              <br />
              I Want To!!
              <br />
              So Pack Your Stuff and Let's buzz off to explore the World Together!!!
              <br />
              Adventures, we Come!!!&#9996;"
            </p>
            
          </div>
          <img src={BeeBee} alt="Beebee" className="mascott-title" />
        </div>

        <div className="news-card-container">

          <NewsCard
            link="news"
            cover={BBSNewsCover}
            inside={BBSNewsInside}
            title="News"
          />

          <NewsCard
            link= ""
            cover={BBSInterviewCover}
            inside={BBSInterviewInside}
            title= "Interviews"
          />

        </div>

      </div>
    );
  }
};

export default AllArticles;