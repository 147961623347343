import { NavLink } from "react-router-dom";

import "./News-card-articles.css";


function NewsCardArticles(props) {

    return (
        <div style={{margin: "50px 20px", display: "inline-block"}}>
            {props.posts.map(post => {
                return (
                    <div style={{margin: "20px"}}>
                        <NavLink to={post.title} key={post.title}>
                            <div class="newspaper-articles">
                                <div className="newspaper-articles-inside">
                                    <div className="newspaper-articles-image">
                                        <img src={post.mainImage} />
                                    </div>
                                    <p>{post.title}</p>
                                </div>
                            </div>
                        </NavLink>
                    </div>
                );
            })}
        </div>
        
    );
};

export default NewsCardArticles;