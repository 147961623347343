import { useState, useEffect } from "react";


import RecipesCard from "../../Components/UI/Recipes/Recipes-card";
import AdvicesCard from "../../Components/UI/Recipes/Advices-card";

import honeybee from "./../../Components/Media/honeybee.webp";
import logo from "./../../Components/Media/logos/babeelicious-logo.png";
import BaBee from "./../../Components/Media/Mascots/Babee/Babee-cooking.png";

import "./All-Recipes.css";


function AllArticles() {
  const [showLogo, setShowLogo] = useState(false);
  const [savedTime, setSavedTime] = useState(0);

  
  useEffect(() => {
    window.scrollTo(0,0);

    // const storedTimestamp = localStorage.getItem("pageLoadTimestamp");
    // console.log("storedTimeStamp:" +storedTimestamp);

    const refreshedTime = new Date().getTime();
    const storedTime = localStorage.getItem("pageLoadTimeAnimationRecipes");

    const elapsedTime = refreshedTime - storedTime;
    
    
    if (storedTime === null ) {

      setShowLogo(true);
      const currentTime = new Date().getTime();
      localStorage.setItem('pageLoadTimeAnimationRecipes', currentTime);

    } else if (elapsedTime > 1800000 || elapsedTime < 5000) {

      setShowLogo(true);
      const currentTime = new Date().getTime();
      localStorage.setItem('pageLoadTimeAnimationRecipes', currentTime);

    } else {
      setShowLogo(false);
    }

    const timeout = setTimeout(() => {
      setShowLogo(false);
    }, 0);

    return () => {
      clearTimeout(timeout);
    };

  }, []);



  if (showLogo === true) {
    return ( 

      <div className="background-image-recipes">

        <div style={{position: "relative", paddingTop: "50px"}}>

          <div style={{ display: "inline-block", margin: "50px auto"}}>
              <img rel="preload" src={logo} alt="Logo" className="logonimation logo-title-xl" />
              <img rel="preload" src={BaBee} alt="Babee" className="logonimation mascott-animation" /> 
          </div>
      
      </div>

    </div>
    );

  } else {
    return (
      
      <div className="background-image-recipes">

        

          <div style={{}}>
            <div>
              <img src={logo} alt="Logo" className="logo-title-xl" />
              
              <p className="welcome-message mascott-message">
                "Hello, Here is your Babee!
                <br />
                I'll show you easy honey-based recipes to try at home.
                <br />
                A new one is posted every week, and between, the youtube channel is coming soon!"
              </p>
              <img src={BaBee} alt="Babee" className="mascott-title" />
            </div>
            
          </div>

          <div className="cover-container">

            <RecipesCard
              link= "recipes"
              image={honeybee}
              title= "All Recipes"     
            />

            <AdvicesCard
              link= "/babee-advice"
              image={honeybee}
              title= "GrandBee's Advices"      
            />

          </div> 
        
      </div>
    );
  }
};

export default AllArticles;
