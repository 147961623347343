import { useEffect } from "react";


function SupportPolicy() {

    useEffect(() => {
        window.scrollTo(0,0);

    }, []);


    return (
        <div className="legals">
            <h2>Support Policy</h2>

            <p>
                At beexplorer.org, we strive to provide exceptional support to our users. Our support team is dedicated to assisting you with any questions, concerns, or technical issues you may encounter while using our website.
            </p>

            <p>
                Our support services include:
            </p>

            <ul>
                <li>Responding to inquiries submitted through our contact form within 24-48 hours</li>
                <li>Providing guidance on using our website's features and functionalities</li>
                <li>Addressing technical issues and troubleshooting errors</li>
            </ul>

            <p>
                If you require support, please don't hesitate to reach out to us via our email <a href="mailto:beexplorer.beexplorer@outlook.com">beexplorer.beexplorer@outlook.com</a>. We kindly ask that you provide detailed information about your inquiry or issue to help us assist you more effectively.
            </p>

            <br />

            <p>
                Thank you for choosing beexplorer.org. We appreciate your trust in our platform, and we're committed to delivering the best possible user experience.
            </p>

        </div>
    );
};

export default SupportPolicy;