import { NavLink } from "react-router-dom";

import arrow from "./../../Media/Icons/scribbles-scribbles-88.png";

import  "./News-card.css";




function NewsCard(props) {


    return (

        <div>
            <div className="newspaper">
                <NavLink to={props.link} className="newspaper-container">
                    <img src={props.inside} className="newspaper-image" />
                </NavLink>
                
                <div className="cover">
                    <img src={props.cover} className="newspaper-image" />
                </div>
            </div>
            <div className="card-news-para"><p>Click to Explore</p><img src={arrow} className="card-news-arrow"/></div>
        </div>
    );
};

export default NewsCard;