import { useEffect } from "react";
import { NavLink } from "react-router-dom";

import "./Articles.css";
import Article from "./Article";
import honeybee from "./../Media/honeybee.webp";
import WeakyCardArticles from "../UI/Weakybeedia/Weaky-card-articles";


function Articles(props) {

    useEffect(() => {
        console.log(props.from);
    }, []);

    return (
        <div className="articles-container">
            {props.posts.map((post) => {
                return (
                    <NavLink to={post.title} key={post.title} className="nav-link" state={props.state} >
                        <WeakyCardArticles
                            key={post._id}
                            image={props.image}
                            post={post}
                            mascott={props.mascott}
                        />
                    </NavLink>
                );
            })}
        </div>
    );
};

export default Articles;

export async function loader(props) {
    return props.posts;
};