import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { logEvent } from 'firebase/analytics';

const trackPageView = (analytics) => {
  logEvent(analytics, 'page_view');
};

const PageViewTracker = ({ analytics }) => {
  const location = useLocation();

  useEffect(() => {
    trackPageView(analytics);
  }, [location, analytics]);

  return null;
};

export default PageViewTracker;
