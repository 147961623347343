import { NavLink } from "react-router-dom";

import "./Recipes-card.css";

import recipeCard from "./../../Media/Recipes/recipes-card-with-babee.png";


function RecipesCard(props) {
     
    return (
        <NavLink to={props.link}>
            <div className="recipes-card">
                <img src={recipeCard} />
            </div>
        </NavLink>
    );
};

export default RecipesCard;

