import { useEffect } from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";

import { FunctionsContext } from "./Components/store/Functions-context";  

import { initializeApp } from "firebase/app";
import { getAnalytics, logEvent } from "firebase/analytics";
import {
    getFunctions,
    // httpsCallable,
    // httpsCallableFromURL,
    connectFunctionsEmulator,
} from "firebase/functions";

import PageViewTracker from "./Components/Utils/PageViewTracker";

import './App.css';

import Root from "./Pages/Root";
import HomePage from "./Pages/Home";
import ErrorPage from "./Pages/Error-Page";
import AllArticles from "./Pages/Articles/All-Articles";
import AllBeeArticles from "./Pages/Articles/All-Bee-Articles";
import TypesOfBees, { loader as loadedBeePost } from "./Pages/Articles/Types-of-bees";
import Honeybees from "./Pages/Articles/Honeybees";
import Honey from "./Pages/Articles/Honey";
import DidYouKnow from "./Pages/Articles/DidYouKnow";
import News from "./Pages/News/News";
import BBSNews from "./Pages/News/BBS-News";
import PostNews from "./Pages/Articles/Posts/PostNews";
import Post from "./Pages/Articles/Posts/Post";
import PostRecipes from "./Pages/Articles/Posts/PostRecipes";
import MainRecipes from "./Pages/Recipes/All-Recipes";
import Recipes from "./Pages/Recipes/Recipes";
import Shop from "./Pages/Shop";
import Donation from "./Pages/Donation";
import Gallery from "./Pages/Gallery/Gallery";
import Beehive from "./Pages/Gallery/Beehive";
import Contest from "./Pages/Contest/Contest";
import Rules from "./Pages/Contest/Rules";
import Winners from "./Pages/Contest/Winners";
import HiveOfFame from "./Pages/Gallery/Hive-of-fame";

import Contact from "./Pages/Legals/Contact";
import TermsConditions from "./Pages/Legals/Terms-and-Conditions";
import SupportPolicy from "./Pages/Legals/Support-policy";
import PrivacyPolicy from "./Pages/Legals/Privacy-policy";



const router = createBrowserRouter([
  {
    path: "/",
    element: <Root />,
    errorElement: <ErrorPage />,
    children: [
      {index: true, path: "", element: <HomePage />},
      
      {path: "all-articles", element: <AllArticles />},

      {path: "all-articles/all-bee-articles", element: <AllBeeArticles />},
      {path: "all-articles/all-bee-articles/bees", element: <TypesOfBees />
        // , loader: loadedBeePost
      },
      {path: "all-articles/all-bee-articles/honeybees", element: <Honeybees />},
      {path: "all-bee-articles", element: <AllBeeArticles />},
      {path: "all-bee-articles/bees", element: <TypesOfBees />},
      {path: "all-bee-articles/honeybees", element: <Honeybees />},
      {path: "bees", element: <TypesOfBees />},
      {path: "honeybees", element: <Honeybees />},

      {path: "honey", element: <Honey />},
      {path: "all-articles/honey", element: <Honey />},

      {path: ":post", element: <Post />},
      {path: ":article/:post", element: <Post />},
      {path: ":articles/:article/:post", element: <Post />},
      {path: ":all-articles/:articles/:article/:post", element: <Post />},

      {path: "did-you-know", element: <DidYouKnow />},
      {path: "all-articles/did-you-know", element: <DidYouKnow />},

      {path: "bbs-news", element: <BBSNews />},
      {path: "bbs-news/:article", element: <News />},
      {path: "news", element: <News />},
      {path: "bbs-news/:article/:post", element: <PostNews />},
      {path: "news/:post", element: <PostNews />},

      {path: "main-recipes", element: <MainRecipes />},
      {path: "main-recipes/recipes", element: <Recipes />},
      {path: "main-recipes/:article/:post", element: <PostRecipes />},
      {path: "recipes", element: <Recipes />},
      {path: "recipes/:postRecipe", element: <PostRecipes />},

      {path: "shop", element: <Shop />},
      {path: "save-the-bees", element: <Donation />},

      {path: "gallery", element: <Gallery />},
      {path: "gallery/hive-of-fame", element: <HiveOfFame />},
      {path: "gallery/beehive", element: <Beehive />},

      {path: "contest", element: <Contest />},
      {path: "contest/rules", element: <Rules />},
      {path: "contest/winners", element: <Winners />},

      {path: "/contact", element: <Contact />},
      {path: "/terms&conditions", element: <TermsConditions />},
      {path: "/support-policy", element: <SupportPolicy />},
      {path: "/privacy-policy", element: <PrivacyPolicy />},
    ],
  }
]);


function App() {

  const {
    REACT_APP_FIREBASE_SDK_API_KEY,
    REACT_APP_FIREBASE_SDK_AUTH_DOMAIN,
    REACT_APP_FIREBASE_SDK_DATABASE_URL,
    REACT_APP_FIREBASE_SDK_PROJECT_ID,
    REACT_APP_FIREBASE_SDK_STORAGE_BUCKET,
    REACT_APP_FIREBASE_SDK_MESSAGING_ID,
    REACT_APP_FIREBASE_SDK_APP_ID,
    REACT_APP_FIREBASE_SDK_MEASUREMENT_ID,
    REACT_APP_ENV,
  
  } = process.env;
  
  
  // For Firebase JS SDK v7.20.0 and later
  const firebaseConfig = {
    apiKey: REACT_APP_FIREBASE_SDK_API_KEY,
    authDomain: REACT_APP_FIREBASE_SDK_AUTH_DOMAIN,
    databaseURL: REACT_APP_FIREBASE_SDK_DATABASE_URL,
    projectId: REACT_APP_FIREBASE_SDK_PROJECT_ID,
    storageBucket: REACT_APP_FIREBASE_SDK_STORAGE_BUCKET,
    messagingSenderId: REACT_APP_FIREBASE_SDK_MESSAGING_ID,
    appId: REACT_APP_FIREBASE_SDK_APP_ID,
    measurementId: REACT_APP_FIREBASE_SDK_MEASUREMENT_ID,
  };
  
  const app = initializeApp(firebaseConfig);
  
  const functions = getFunctions(app);
  const analytics = getAnalytics(app);
  
  if (REACT_APP_ENV === "development") {
    connectFunctionsEmulator(functions, "localhost", 5001);
  }


  useEffect(() => {
    window.scrollTo(0,0);

    logEvent(analytics, 'page_view');
  }, []);


  return (
    <FunctionsContext.Provider value={{functions}} >
      <div className="App">
        <RouterProvider router={router}>
          <PageViewTracker analytics={analytics} />
        </RouterProvider>
      </div>
    </FunctionsContext.Provider>
  );
}

export default App;
