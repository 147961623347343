import { useState, useEffect } from "react";
import { useParams, Link} from "react-router-dom";
import Markdown from "react-markdown";
import rehypeRaw from "rehype-raw";

import "./Post.css";

import Quiz from "./../../../Components/UI/Weakybeedia/Quiz";

import backImage from "./../../../Components/Media/honeybee-pink-flower-sunrise.jpg";
import BackButton from "./../../../Components/UI/Buttons/Back-button"

import Beeatrice from "./../../../Components/Media/Mascots/Beeatrice/Beeatrice-reading.png";
import BeeatriceGreeting from "./../../../Components/Media/Mascots-reversed/Beeatrice/Beeatrice-greeting-with-book.png";
import postsBees from "./../../../Components/Data-permanent/Weakybeedia/bees/bees.json";
import postsHoneybees from "./../../../Components/Data-permanent/Weakybeedia/bees/honeybees.json";
import postsHoney from "./../../../Components/Data-permanent/Weakybeedia/honey/honey.json";

const Post = () => {
    const [post, setPost] = useState("");


    const params = useParams();
    const backTo = "/" + params.article;
    const backToPage = "Back to " + params.article + " articles";


    const fetchPost = async () => {

        try {
            let wantedArticle;

            if (params.article === "bees") {
                wantedArticle = postsBees.find(article => article.title === params.post );
            } else if (params.article === "honeybees") {
                wantedArticle = postsHoneybees.find(article => article.title === params.post );
            } else if (params.article === "honey") {
                wantedArticle = postsHoney.find(article => article.title === params.post );
            }

            setPost(wantedArticle);
        } catch (error) {
            console.error('Error fetching post:', error);
        }
    };


    useEffect(() => {
        window.scrollTo(0,0);
    
        fetchPost();

    }, [post]);

    return (
        <div style={{position: "relative"}}>
            <div className="background-image"></div>

            <div className="post-container-weaky">
                <div className="post-title-content">
                    <div className="post-top">
                        <div>
                            <h1 className="post-title">{post.title}
                                <img src={Beeatrice} className="post-mascott"/>
                            </h1>
                        </div>
                        
                        
                    </div>
                    
                    <Markdown className="post-content" rehypePlugins={[rehypeRaw]}>{post.content}</Markdown>
                </div>

                <div>
                    <img src={BeeatriceGreeting} className="mascott-punchline"/>
                    <p className="post-punchline mascott-message">"{post.punchline}"</p>
                </div>

                <div>
                    <div class="post-author-date"><p>{post.author}</p></div>
                    
                    <div class="post-author-date"><p>{post.date}</p></div>
                </div>

                {post.choices && <Quiz post={post} />}

                <BackButton 
                    image={backImage} 
                    width={200} 
                    height={200} 
                    unit="px"
                    backToPage={backToPage}
                    backTo={backTo}
                    fontFamily="eb garamond"
                    fontSize="1.3rem"
                />

            </div>
        </div>  
        
    );
};

export default Post;