import { NavLink } from "react-router-dom";

import "./Weaky-card-articles.css";


function WeakyCardArticles(props) {


    return (
        <div className="weaky-book-article-main-container">
            <div className="weaky-book-article-container1">
                <img 
                src={props.post.mainImage.length < 1 ? props.image : props.post.mainImage} 
                />
            </div>

            <div className="weaky-book-article-container2">
                {/* <img src={props.image} style={{opacity: "0"}}/> */}
                <div className="weaky-book-article-inside-container">
                    <p className="weaky-cover-article-title">{props.post.title}</p>
                </div>
                <NavLink to={props.post.title} className="">
                    <div className="weaky-article-honeycomb">Explore</div>
                </NavLink> 
            </div>










            {/* <div className="weaky-book-article">
                    
                    <div className="weaky-book-inside">
                            <div className="weaky-book-inside-container">
                                <div className="weaky-cover-inside-heading">{props.title}</div>
                                <p className="weaky-cover-inside-description">{props.description}</p>
                            </div>
                            
                            <NavLink to={props.link} className="">
                                <div className="weaky-honeycomb">Explore</div>
                            </NavLink>
                        
                    </div>


            </div> */}

        </div>
        
    );
};

export default WeakyCardArticles;