import { useLoaderData } from "react-router-dom";
import { useEffect, useState } from "react";

import "./All-Articles.css";

import backImage from "./../../Components/Media/honeybee-pink-flower-sunrise.jpg";
import BackButton from "./../../Components/UI/Buttons/Back-button"

import Articles from "./../../Components/Articles/Articles";
import logo from "./../../Components/Media/logos/weakybeedia-logo-silver-green.png";
import Beeatrice from "./../../Components/Media/Mascots/Beeatrice/Beeatrice-greeting-with-book.png";
import honeybee from "./../../Components/Media/Weakybeedia/honeybee-dark-room.jpg";
import posts from "./../../Components/Data-permanent/Weakybeedia/bees/bees.json";


function AllArticles() {
  // const postsDB = useLoaderData();

  useEffect(() => {
    window.scrollTo(0,0);
  }, []);
    

  return (
    <div style={{position: "relative"}}>

      <div className="background-image-articles-repeated"></div>

      <div style={{position: "relative", paddingTop: "15vh"}}>

        <img src={logo} alt="Logo" className="logo-title-xl" />

        <div>
          <p className="welcome-message mascott-message">
                "All different types of Bees"
          </p>
        </div>
        <img src={Beeatrice} alt="Beeatrice" className="mascott-title" />
        
        
        <div style={{margin: "20px auto"}}>
          <Articles 
            posts={posts}
            image={honeybee}
          />
        </div>

        <BackButton 
                image={backImage} 
                width={200} 
                height={200} 
                unit="px"
                backToPage="Back to bee menu"
                backTo="/all-bee-articles"
                fontFamily="eb garamond"
                fontSize="1.3rem"
            />
        
      </div>
    </div>  
  );
};

export default AllArticles;


// export async function loader() {
//   try {
//     const response = await fetch('http://localhost:5000/bees');

//     if (!response.ok) {
//       throw new Error("Couldn't fetch posts");
//     }

//     const data = await response.json();

//     return data; // Ensure the loader returns an object with a 'posts' property
//   } catch (error) {
//     console.error("Error fetching data:", error.message);
//     return { posts: ["error fetching data"] }; 
//   }
// };