import { NavLink } from "react-router-dom";

import "./Recipes-card-articles.css";


function RecipesCardArticles(props) {
     
    return (
        <div>
            {props.posts.map(post => {
                return (
                    <div style={{width: "fit-content", margin: "0 auto"}}>
                    <NavLink to={post.title}>
                        <div class="recipe-card">
                            <div className="recipe-card-image-container">
                                <img src={post.mainImage} className="recipe-card-image"/>
                            </div>
                            
                            <h2>{post.title}</h2>
                        </div>
                    </NavLink>
                    </div>
                );
            })}
        </div>
    );
};

export default RecipesCardArticles;

