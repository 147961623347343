import { createElement, useEffect } from "react";

import "./Donation.css";

import beeMelanie from "./../Components/Media/by-Melanie-from-pixabay-bumblebee-7336814.jpg";
import beeatrice from "./../Components/Media/Mascots/Beeatrice/Beeatrice-ambeesador.png";
import queen from "./../Components/Media/Mascots/Queen-Elizabeeth/Queen-ambeesador.png";
import dronan from "./../Components/Media/Mascots/Dronan/Dronan-ambeesador.png";
import beenta from "./../Components/Media/Mascots/Beenta/Beenta-painting.png";
import beebee from "./../Components/Media/Mascots/BeeBee/Beebee-reporting.png";
import logo from "./../Components/Media/beexplorer-logo.png";
import QR from "./../Components/Media/QR-donation.png";


function Donation() {

  function scrollTo() {
    const ambeesador = document.getElementsByClassName("mascots-donation")[0];
    ambeesador.scrollIntoView({ behavior: 'smooth', block: 'start' });
  };

  useEffect(() => {
    window.scrollTo(0,0);

    const script = document.createElement("script");
          script.src = "https://js.stripe.com/v3/buy-button.js";
          script.async = true;
          
          document.body.appendChild(script);

          return () => {
            document.body.removeChild(script);
          };
   
  }, []);


  return (
    <div style={{position: "relative"}}>
      <div className="background-image-donation"></div>

      <div className="donation-container">
        
        <div style={{width: "fit-content", margin: "0 auto"}}>
          <h1>Let's Bee Heroes</h1>
        </div>
        
        <div>
          <h3>Become Ambeesador<br /> to Support and Contribute to Helping Save Bees!</h3>
        </div>

        <div className="beeMelanie-container">
          <img src={beeMelanie} className="beeMelanie" alt="Bumblebee by Melanie from Pixabay"/>
        </div>

        <p>
          Bees play a critical role in our ecosystem by pollinating over 80% of flowering plants worldwide, 
          including crops, thereby helping produce one-third of the food we eat. 
          Unfortunately, bees are facing numerous threats, including habitat loss, pesticides, and climate change, 
          leading to critical declines in bee populations. Without them, the human species and all others on Earth are heading towards extinction. 
          By supporting our bee conservation program, we will protect the planet's pollinators, 
          thus contributing to preserving biodiversity, safeguarding food sources, and ensuring a sustainable future for all. 
          Your donations are vital!
        </p>
        <br />
        <p>
          Coming in 2025!<br />
          Bee a part of History, Beecome an Ambeesador!<br />
          Original and fun YouTube videos showcasing the actions done because of your contributions will be produced. 
          On the serious note, we will be setting beehives, planting crops, administering medicine,<br />
          setting traps against Asian Hornets, and on the other side, plenty of fun surprises!<br /> 
          Donate and witness first hand the impact of your support.<br />
          Bees need us as much as we need them. We can have fun while helping them, and thus, ourselves!<br />
          Let's bee Heroes!
        </p>

        <div style={{marginTop: "20px"}}>
          <div style={{width: "100%"}}>
            <div style={{display: "inline-block"}}>
              <stripe-buy-button
                buy-button-id="buy_btn_1OtBcgC9cBRTqwIXNBD3izBN"
                publishable-key="pk_live_51NoWyJC9cBRTqwIXXE2HBMCcqdXVrEqdnbVfpJajk0hA60O7cX8FbWhbJqowuij5HNk62yHrnzSW00KgUSGxTv8O00ZOoXMVoj"
              >
              </stripe-buy-button>
            </div>

            <div style={{float: "right", width: "10%", display: "inline-block", position: "absolute", paddingTop: "19px"}}>
              <img src={QR} style={{width: "100%"}}/>
            </div>
          </div>
        </div>

        <div className="background-image-donation-filling">
          <div className="mascots-donation">
            <img src={beeatrice} style={{ width: "9%"}}/>
            <img src={queen} style={{ width: "15%"}}/>
            <img src={dronan} style={{ width: "13%"}}/>
          </div>

          <div className="mascots-donationg">
            <img src={beenta} style={{ width: "15%"}}/>
            <img src={beebee} style={{ width: "4.9%"}}/>
          </div>
        </div>


        {/* <button className="button-main" onClick={scrollTo}>Become Ambeesador</button> */}


        {/* <div style={{margin: "40px auto 20px"}}>
          <stripe-buy-button
            buy-button-id="buy_btn_1OsvXUC9cBRTqwIXe1fCdqKW"
            publishable-key="pk_live_51NoWyJC9cBRTqwIXXE2HBMCcqdXVrEqdnbVfpJajk0hA60O7cX8FbWhbJqowuij5HNk62yHrnzSW00KgUSGxTv8O00ZOoXMVoj"
          >
          </stripe-buy-button>
        </div> */}


        {/* <div>
          <h1>AMBEESADORS</h1>
          <p>
            The first one thousand donors will officially become contributors and Ambassadors of the Beexplorer cause. 
            A badge will be provided as proof of your new status. 
            As we continue to grow and support bees together, Ambassadors will receive ongoing benefits, including significant discounts on honey and other products, complimentary attendance at events, and permanent shout-outs in our YouTube videos.
          </p>

          <div className="badges-donation">
            <img src={logo}/> <img src={logo}/> <img src={logo}/>
          </div> 
        </div> */}
        
      </div>

    </div>
  );

};

export default Donation;